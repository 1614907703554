export const attributesType = {
    TEXT: 27,
    EMAIL: 11,
    PHONE: 12,
    DOCUMENT: 15,
    SINGLE_SELECTION: 7,
}

export const valueBasedField = [
    attributesType.TEXT,
    attributesType.EMAIL,
    attributesType.PHONE,
    attributesType.DOCUMENT,
]

export const optionBasedField = [attributesType.SINGLE_SELECTION]

export const documentFileTypes = [
    // 'application/msword',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
]
export const documentTypeFileExtension = {
    // 'application/msword': 'doc',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/pdf': 'pdf',
}
export const imageFileType = ['image/jpeg', 'image/png']
export const imageFileExtension = ['jpeg', 'jpg', 'png']
