import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import SuccessfulValidationSVG from './SuccessfulValidationSVG'
import useValidateOTP from '../hooks/useValidateOTP'
import useRegenerateOTP from '../hooks/useRegenerateOTP'
import { Button } from '../../../components/button/Button'
import { sinUpSteps } from '../constants'
import { logo } from '../../../constants/media'
import Alert from '../../../components/Alert'

const OTPStep = ({ email, getParams, setSearchParams, searchParams }) => {
    const { t } = useTranslation('signup')

    // OTP
    const [otp, setOtp] = useState('')
    const [OTPError, setOTPError] = useState('')
    const { mutate: validateOTP, status: validateOTPStatus, data: validateOTPData } = useValidateOTP()
    const { mutate: regenerateOTP, status: regenerateOTPStatus, reset: resetRegenerateOTP } = useRegenerateOTP()
    const handelChangeEmail = () => {
        let params = getParams(searchParams)
        delete params['email']
        setSearchParams({ ...params, step: sinUpSteps.email })
    }
    const onOTPChange = (e) => {
        const otpValue = e.target.value?.replace(/\s/g, '')
        console.log('otpValue', otpValue)
        if (otpValue?.length <= 4) {
            setOTPError(false)
            resetRegenerateOTP()
            setOtp(otpValue)
        }
        if (otpValue?.length === 4) {
            handleOTPSubmit(otpValue)
        }
    }
    const handleOTPSubmit = (otp) => {
        if (otp) {
            const formBody = {
                email,
                otp: otp,
            }
            validateOTP(
                { formData: formBody },
                {
                    onSuccess: () => {
                        continueToAttributes()
                    },
                    onError: () => {
                        setOTPError(true)
                        setOtp('')
                    },
                }
            )
        }
    }
    const continueToAttributes = () => {
        setSearchParams({ ...getParams(searchParams), step: sinUpSteps.userDetails })
    }
    // Regenerate OTP
    const [timeLeft, setTimeLeft] = useState(15)
    useEffect(() => {
        const timer = setInterval(() => {
            if (timeLeft > 0) {
                setTimeLeft((prevTime) => prevTime - 1)
            }
        }, 1000)

        // Clean up the interval on unmount
        return () => clearInterval(timer)
    }, [timeLeft])

    const handelRegenerateOTP = () => {
        setOTPError(false)
        const formBody = {
            email,
        }
        regenerateOTP(
            { formData: formBody },
            {
                onSuccess: () => {
                    setTimeLeft(15)
                    setTimeout(() => {
                        resetRegenerateOTP()
                    }, 5000)
                },
                onError: (error) => {},
            }
        )
    }

    return (
        <>
            <img src={logo} className=' h-16 mx-auto' alt='' />
            <p className=' mb-0 pt-8 text-2xl text-center font-semibold text-brandDark'>{t('signup:confirm_email')}</p>
            <p className=' pt-2 text-sm text-center  text-brandTextPrimary'>
                <span className=' text-[#637381]'>{t('signup:otp_sent_to')} </span>
                <span className=' text-[#637381] font-bold'>{email}</span>
            </p>

            <p
                className=' pt-2 text-sm text-brandPrimary text-center cursor-pointer'
                onClick={() => handelChangeEmail()}>
                {t('signup:change_email')}
            </p>

            <div className=' !pt-7   w-full'>
                <div className=' flex flex-col gap-y-2'>
                    <label htmlFor='otp' className=' !text-sm text-brandGray4'>
                        {t('signup:enter_otp')} <span className='text-red-500'>*</span>
                    </label>
                    <input
                        type='number'
                        onChange={onOTPChange}
                        value={otp}
                        placeholder={t('signup:otp_placeholder')}
                        onKeyDown={(evt) => ['e', 'E', '+', '-', '.'].includes(evt.key) && evt.preventDefault()}
                        className=' py-2 px-3 rounded-lg placeholder:text-sm border max-w-40 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none'
                    />
                </div>
                {OTPError && (
                    <div className=' pt-4'>
                        <Alert type='error'>{t('signup:invalid_otp')}</Alert>
                    </div>
                )}
                <div className=' pt-6'>
                    {regenerateOTPStatus === 'success' && (
                        <div className=' pb-2'>
                            <Alert type='success'>{t('signup:otp_sent_successfully')}</Alert>
                        </div>
                    )}

                    {timeLeft > 0 && (
                        <p className=' mb-0 text-sm'>
                            <span className=' text-[#637381]'>{t('signup:resend_otp_in')} </span>
                            <span className=' text-[#023047] font-bold '>
                                00:{timeLeft < 10 && 0}
                                {timeLeft}
                            </span>
                        </p>
                    )}
                    {timeLeft === 0 && (
                        <p
                            onClick={handelRegenerateOTP}
                            className=' mb-0  text-sm text-brandPrimary font-medium cursor-pointer'>
                            {t('signup:resend_otp')}
                        </p>
                    )}
                </div>
            </div>
        </>
    )
}
export default OTPStep
