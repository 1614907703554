import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const validateOTPURL = process.env.REACT_APP_VALIDATE_OPT
const useValidateOTP = () => {
    const validateOTP = async ({ formData }) => {
        const res = await api.post(validateOTPURL, formData)
        return res.data?.response_body?.[0]
    }
    return useMutation({ mutationFn: validateOTP })
}
export default useValidateOTP
