import { useAuth } from 'react-oidc-context'
import { lockSVG, logo, minusNotificationSVG, powerSwitchSVG } from '../../constants/media'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { OrderContext } from '../../context/OrdersProvider'
import CancelSubscriptionModal from './components/CancelSubscriptionModal'

const Header = () => {
    const auth = useAuth()
    const { t } = useTranslation('common')
    const orderDetails = useContext(OrderContext)
    const orderStatus =
        orderDetails?.ordersData?.response_body?.order_status === 'Placed' ||
        orderDetails?.ordersData?.response_body?.order_status === 'Completed'

    const navigate = useNavigate()
    const userName =
        auth.user?.profile?.name || auth.user?.profile?.given_name || auth.user?.profile?.preferred_username
    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const userMenuRef = useRef()
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
                setIsUserMenuOpen(false)
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [userMenuRef])
    const handelLogout = () => {
        navigate('/logout')
        setIsUserMenuOpen(false)
    }

    return (
        <>
            <section className=' bg-white py-4 fixed z-10 top-0 border-b border-brandStroke w-full  px-5  '>
                <div className=' flex items-center relative justify-between max-w-screen-brandMax mx-auto w-full'>
                    <Link to={'/'} className=' h-[60px]'>
                        <img src={logo} alt='Logo' className='  h-full' />
                    </Link>
                    <div className=' flex items-center gap-x-2'>
                        {auth.isAuthenticated ? (
                            <>
                                <svg
                                    width='40'
                                    height='40'
                                    viewBox='0 0 40 40'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <rect width='40' height='40' rx='20' fill='#BFBFBF' />
                                    <path
                                        d='M27.7337 25.6133C27.3127 24.6159 26.7016 23.7099 25.9346 22.9459C25.17 22.1796 24.2642 21.5687 23.2672 21.1468C23.2583 21.1423 23.2494 21.1401 23.2404 21.1356C24.6311 20.1311 25.5351 18.495 25.5351 16.649C25.5351 13.591 23.0574 11.1133 19.9994 11.1133C16.9413 11.1133 14.4636 13.591 14.4636 16.649C14.4636 18.495 15.3677 20.1311 16.7583 21.1378C16.7494 21.1423 16.7404 21.1445 16.7315 21.149C15.7315 21.5709 14.8342 22.1758 14.0641 22.9481C13.2978 23.7128 12.6869 24.6186 12.265 25.6155C11.8505 26.5915 11.627 27.6379 11.6065 28.6981C11.6059 28.7219 11.6101 28.7456 11.6188 28.7678C11.6275 28.79 11.6406 28.8102 11.6572 28.8273C11.6738 28.8443 11.6937 28.8579 11.7157 28.8671C11.7377 28.8764 11.7612 28.8811 11.7851 28.8811H13.1244C13.2226 28.8811 13.3007 28.803 13.3029 28.707C13.3476 26.9838 14.0395 25.37 15.2628 24.1468C16.5284 22.8811 18.2092 22.1847 19.9994 22.1847C21.7895 22.1847 23.4703 22.8811 24.736 24.1468C25.9592 25.37 26.6511 26.9838 26.6958 28.707C26.698 28.8052 26.7761 28.8811 26.8744 28.8811H28.2136C28.2375 28.8811 28.2611 28.8764 28.283 28.8671C28.305 28.8579 28.3249 28.8443 28.3415 28.8273C28.3582 28.8102 28.3712 28.79 28.3799 28.7678C28.3886 28.7456 28.3928 28.7219 28.3922 28.6981C28.3699 27.6311 28.1489 26.5932 27.7337 25.6133ZM19.9994 20.4883C18.9748 20.4883 18.0105 20.0887 17.2851 19.3633C16.5596 18.6378 16.1601 17.6736 16.1601 16.649C16.1601 15.6244 16.5596 14.6602 17.2851 13.9347C18.0105 13.2093 18.9748 12.8097 19.9994 12.8097C21.0239 12.8097 21.9882 13.2093 22.7136 13.9347C23.4391 14.6602 23.8386 15.6244 23.8386 16.649C23.8386 17.6736 23.4391 18.6378 22.7136 19.3633C21.9882 20.0887 21.0239 20.4883 19.9994 20.4883Z'
                                        fill='white'
                                    />
                                </svg>
                                <p className=' text-brandTextPrimary text-sm'>{userName}</p>

                                <div
                                    className=' cursor-pointer'
                                    onClick={() => {
                                        if (!isUserMenuOpen) setIsUserMenuOpen(true)
                                    }}>
                                    <svg
                                        width='15'
                                        height='15'
                                        viewBox='0 0 10 10'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'>
                                        <path
                                            d='M5 7.125C4.90625 7.125 4.82812 7.09375 4.75 7.03125L1.15625 3.5C1.01562 3.35938 1.01562 3.14063 1.15625 3C1.29687 2.85938 1.51563 2.85938 1.65625 3L5 6.26562L8.34375 2.96875C8.48438 2.82812 8.70312 2.82812 8.84375 2.96875C8.98438 3.10938 8.98438 3.32813 8.84375 3.46875L5.25 7C5.17187 7.07812 5.09375 7.125 5 7.125Z'
                                            fill='#8899A8'
                                        />
                                    </svg>
                                </div>
                            </>
                        ) : null}
                    </div>
                    {isUserMenuOpen ? (
                        <div className=' absolute z-20 top-[75px]  right-0  ' ref={userMenuRef}>
                            <div className=' flex justify-end mr-4 '>
                                <svg
                                    width='18'
                                    height='10'
                                    viewBox='0 0 12 6'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className=' text-gray-100'>
                                    <path d='M12 6L6 0L0 6H12Z' fill='currentColor' />
                                </svg>
                            </div>
                            <div>
                                <div className='border bg-white  rounded'>
                                    {/* <div
                                    className={` flex cursor-pointer px-8  py-4  w-full hover:bg-gray-100 transition-all duration-200 text-deleteRed items-center gap-x-2 `}>
                                    <img src={lockSVG} alt='' />
                                    <p className=' text-brandDark font-semibold'> {t('change_password')}</p>
                                </div> */}
                                    {orderStatus && (
                                        <div
                                            onClick={() => {
                                                setIsDeleteModalOpen(true)
                                                setIsUserMenuOpen(false)
                                            }}
                                            className={` flex cursor-pointer px-8 py-4  w-full hover:bg-gray-100 transition-all duration-200 text-deleteRed items-center gap-x-2 `}>
                                            <img src={minusNotificationSVG} alt='' />
                                            <p className=' text-brandDark font-semibold'> {t('cancel_subscription')}</p>
                                        </div>
                                    )}
                                    <div
                                        onClick={handelLogout}
                                        className={` flex cursor-pointer px-8 py-4 hover:bg-gray-100 transition-all duration-200  w-full  text-deleteRed items-center gap-x-2 `}>
                                        <img src={powerSwitchSVG} alt='' />
                                        <p className=' text-brandDark font-semibold'> {t('logout')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </section>
            {isDeleteModalOpen && <CancelSubscriptionModal setIsDeleteModalOpen={setIsDeleteModalOpen} />}
        </>
    )
}
export default Header
