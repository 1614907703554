import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'
import useSignupPost from '../hooks/usePostSignup'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { checkGreen, checkGrey, eyeIcon, eyeIconClosed } from '../../../constants/media'
import { Button } from '../../../components/button/Button'
import { logo } from '../../../constants/media'

const FinishingSignup = ({ email }) => {
    const auth = useAuth()
    const { t } = useTranslation('signup')
    const { mutate: postSignup, status: signupPostCallStatus } = useSignupPost()
    const [formData, setFormData] = useState({
        fullName: '',
        password: '',
    })
    const [errors, setErrors] = useState({})
    const [showPassword, setShowPassword] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)

    // User Name
    const handleUsernameKeyDown = (e) => {
        const { key } = e

        const isAlphanumeric = /^[a-zA-Z0-9 ]*$/

        // Prevent typing special characters and multiple consecutive spaces
        if (!isAlphanumeric.test(key) || (key === ' ' && (e.target.value.endsWith(' ') || e.target.value === ''))) {
            e.preventDefault()
        }
    }

    // Password
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value

        setFormData((prevFormData) => ({
            ...prevFormData,
            password: newPassword,
        }))

        if (!isValidPassword(newPassword)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: `${t('signup:please_enter_valid_password')}`,
            }))
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                password: '',
            }))
        }
    }
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword)
    }

    const handleUsernameInput = (e) => {
        let newValue = e.target.value

        // Ensure the new value is alphanumeric and within the max length
        if (/^[a-zA-Z0-9 ]*$/.test(newValue) && newValue.length <= 24) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                fullName: newValue,
            }))

            if (newValue.trim().length > 2) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullName: '',
                }))
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullName: `${t('signup:full_name_must_be_atleast_3_char')}`,
                }))
            }
        }
    }
    const handlePaste = (e) => {
        e.preventDefault()
        const pasteText = e.clipboardData.getData('text')
        const isAlphanumeric = /^[a-zA-Z0-9 ]*$/

        if (isAlphanumeric.test(pasteText)) {
            let newValue =
                e.target.value.slice(0, e.target.selectionStart) +
                pasteText.slice(0, 24 - e.target.value.length) +
                e.target.value.slice(e.target.selectionEnd)

            if (newValue.length > 24) {
                newValue = newValue.slice(0, 24)
            }

            setFormData((prevFormData) => ({
                ...prevFormData,
                fullName: newValue,
            }))

            if (newValue.trim().length > 2) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullName: '',
                }))
            } else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    fullName: `${t('signup:full_name_must_be_atleast_3_char')}`,
                }))
            }
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let errors = {}

        //full name validations
        if (!formData.fullName) {
            errors.fullName = `${t('signup:please_enter_full_name')}`
        } else if (formData.fullName.length <= 2) {
            errors.fullName = `${t('signup:full_name_must_be_atleast_3_char')}`
        }

        //password check
        if (formData.password === '') {
            errors.password = `${t('signup:please_enter_valid_password')}`
        } else if (!isValidPassword(formData.password)) {
            errors.password = `${t('signup:please_enter_valid_password')}`
        }

        setErrors(errors)

        // If there are no errors, submit the form
        if (Object.keys(errors).length === 0) {
            setIsFormSubmitting(true)
            const formBody = {
                full_name: formData.fullName,
                email: email,
                password: formData?.password,
            }

            postSignup(
                { formData: formBody },
                {
                    onSuccess: async () => {
                        toast.success(`${t('signup:user_created_successfully')}`)
                        auth.signinResourceOwnerCredentials({
                            username: email,
                            password: formData?.password,
                        }).then(() => {
                            window.location.reload()
                        })
                    },
                    onError: (error) => {
                        setIsFormSubmitting(false)
                        const errorMessage =
                            error?.response?.data?.response_message || `${t('signup:an_error_occurred')}`
                        toast.error(errorMessage)
                    },
                }
            )
        }
    }

    const isValidPassword = (pw) => {
        return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9 ]).{12,64}$/.test(pw)
    }
    return (
        <section>
            <img src={logo} className=' h-16 mx-auto' alt='' />
            <p className=' mb-0 pt-8 text-2xl text-center font-semibold text-brandDark'>{t('signup:email_verified')}</p>
            <p className=' pt-2 text-sm text-center  text-brandTextPrimary'>{t('signup:let_us_create_profile')}</p>
            <form onSubmit={handleSubmit} noValidate className='space-y-4'>
                <div>
                    <label className='block  text-sm text-brandGray4'>
                        {t('signup:full_name')}
                        <span className='text-red-500'>*</span>
                    </label>
                    <input
                        type='text'
                        name='fullName'
                        value={formData.fullName}
                        onKeyDown={handleUsernameKeyDown}
                        onInput={handleUsernameInput}
                        onPaste={handlePaste}
                        className={`mt-1 py-2 px-4 w-full border ${errors.fullName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:!border-brandPrimary focus:outline-none focus:ring-0`}
                        placeholder={t('signup:full_name')}
                        maxLength={24}
                    />
                    {errors.fullName && <p className='text-red-500 text-sm'>{errors.fullName}</p>}
                </div>
                <div>
                    <label className='block text-sm text-brandGray4'>
                        {t('signup:password')}
                        <span className='text-red-500'>*</span>
                    </label>
                    <div className='relative'>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            name='password'
                            value={formData.password}
                            onChange={(e) => {
                                handlePasswordChange(e)
                            }}
                            className={`mt-1 py-2 px-4 w-full border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-md  focus:!border-brandPrimary focus:outline-none focus:ring-0 pr-10 `}
                            placeholder={t('signup:password')}
                            maxLength={64}
                        />
                        <span className='absolute inset-y-0 right-0 flex items-center pr-3'>
                            <button type='button' onClick={togglePasswordVisibility} className='focus:outline-none'>
                                {showPassword ? (
                                    <img src={eyeIcon} alt='Hide' className='h-5 w-5' />
                                ) : (
                                    <img src={eyeIconClosed} alt='Show' className='h-5 w-5' />
                                )}
                            </button>
                        </span>
                    </div>

                    {errors.password && <p className='text-red-500 text-sm'>{errors.password}</p>}
                    <div className='text-brandTextPrimary text-sm font-normal leading-5 mt-2 flex-wrap'>
                        <p className='my-1 flex items-center'>
                            <span>
                                {formData.password && formData.password.length >= 12 ? (
                                    <img src={checkGreen} alt='' />
                                ) : (
                                    <img src={checkGrey} alt='' />
                                )}
                            </span>
                            <span className='ml-2'>{t('signup:at_least_12_characters')}</span>
                        </p>
                        <p className='my-1 flex items-center'>
                            <span>
                                {formData.password && /[A-Z]/.test(formData.password) ? (
                                    <img src={checkGreen} alt='' />
                                ) : (
                                    <img src={checkGrey} alt='' />
                                )}
                            </span>
                            <span className='ml-2'> {t('signup:one_or_more_uppercase_letters')}</span>
                        </p>
                        <p className='my-1 flex items-center'>
                            <span>
                                {formData.password && /[!@#$%^&*"'()_+{}\[\]:;<>,.?~\\/-]/.test(formData.password) ? (
                                    <img src={checkGreen} alt='' />
                                ) : (
                                    <img src={checkGrey} alt='' />
                                )}
                            </span>
                            <span className='ml-2'>{t('signup:one_or_more_special_characters_or_symbols')}</span>
                        </p>
                        <p className='my-1 flex items-center'>
                            <span>
                                {formData.password && /[a-z]/.test(formData.password) ? (
                                    <img src={checkGreen} alt='' />
                                ) : (
                                    <img src={checkGrey} alt='' />
                                )}
                            </span>
                            <span className='ml-2'>{t('signup:one_or_more_lowercase_letters')}</span>
                        </p>
                        <p className='my-1 flex items-center'>
                            <span>
                                {formData.password && /[\d]/.test(formData.password) ? (
                                    <img src={checkGreen} alt='' />
                                ) : (
                                    <img src={checkGrey} alt='' />
                                )}
                            </span>
                            <span className='ml-2'>{t('signup:one_or_more_numbers')} </span>
                        </p>
                    </div>
                </div>
                <Button
                    className='w-[100%] font-medium'
                    variant='default'
                    disabled={isFormSubmitting}
                    isLoading={isFormSubmitting}
                    type='submit'>
                    {isFormSubmitting ? t('signup:processing') : t('signup:continue')}
                </Button>
            </form>
        </section>
    )
}
export default FinishingSignup
