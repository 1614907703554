import { createContext } from 'react'
import useGetOrders from '../hooks/useGetOrders'

export const OrderContext = createContext({})
const OrdersProvider = ({ children }) => {
    const { data: ordersData, status: ordersStatus } = useGetOrders()

    return <OrderContext.Provider value={{ ordersData, ordersStatus }}>{children}</OrderContext.Provider>
}
export default OrdersProvider
