import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const uploadImageURL = process.env.REACT_APP_IMAGE
const useUploadImages = () => {
    const UploadImages = async ({ formBody }) => {
        const res = await api.post(uploadImageURL, formBody)
        return res?.data
    }
    return useMutation({ mutationFn: UploadImages })
}
export default useUploadImages
