export const CompletedStep = ({ stepName, isLastStep }) => {
    console.log(stepName?.length)
    return (
        <div className=''>
            <div className=' flex items-center'>
                <div className=' w-[33px] h-[33px] border-2 rounded-full border-brandPrimary flex justify-center items-center'>
                    <svg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                            d='M17.0437 0.768945C16.7906 0.51582 16.3969 0.51582 16.1437 0.768945L6.58125 10.0502L1.85625 5.40957C1.60312 5.15645 1.20937 5.18457 0.956246 5.40957C0.703121 5.66269 0.731246 6.05644 0.956246 6.30957L5.93437 11.1471C6.10312 11.3158 6.32812 11.4002 6.58125 11.4002C6.83437 11.4002 7.03125 11.3158 7.22812 11.1471L17.0437 1.6127C17.2969 1.41582 17.2969 1.02207 17.0437 0.768945Z'
                            fill='#FFB703'
                        />
                    </svg>
                </div>
                {!isLastStep && <ProgressLine isCompleted />}
            </div>
            <p className='  text-xs font-medium text-brandDark pt-2' style={{ marginLeft: `-${stepName?.length}px` }}>
                {stepName}
            </p>
        </div>
    )
}

export const CurrentStep = ({ stepName, isLastStep, isFilled }) => {
    return (
        <div>
            <div className=' flex items-center'>
                <div className=' w-[33px] h-[33px] border-2 rounded-full border-brandPrimary flex justify-center items-center'>
                    <div className='h-[17px] w-[17px] bg-brandPrimary rounded-full'></div>
                </div>
                {!isLastStep && <ProgressLine isCompleted={isFilled} />}
            </div>
            <p className='  text-xs font-medium text-brandDark pt-2' style={{ marginLeft: `-${stepName?.length}px` }}>
                {stepName}
            </p>
        </div>
    )
}

export const PendingStep = ({ stepName, isLastStep }) => {
    return (
        <div>
            <div className=' flex items-center'>
                <div className=' w-[33px] h-[33px] border-2 rounded-full border-brandStroke '></div>
                {!isLastStep && <ProgressLine isCompleted={false} />}
            </div>
            <p className=' text-xs font-medium text-brandDark pt-2' style={{ marginLeft: `-${stepName?.length}px` }}>
                {stepName}
            </p>
        </div>
    )
}

export const ProgressLine = ({ isCompleted }) => {
    return <div className={` h-[2px]  w-28 ${isCompleted ? 'bg-brandPrimary' : 'bg-brandStroke'} `} />
}
