import { useQuery } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const storeDetailsURL = process.env.REACT_APP_APPLICANT_STORE_DETAILS
const useGetStoreDetails = ({ orderId }) => {
    const getStoreDetails = async () => {
        const res = await api.get(storeDetailsURL)
        return res.data?.response_body
    }
    const filterFn = (data) => {
        const filteredOrders = data?.filter((od) => od?.order === orderId && od?.store_link)
        return filteredOrders
    }
    return useQuery({
        queryKey: ['storeDetails'],
        queryFn: getStoreDetails,
        select: orderId ? filterFn : (data) => data,
    })
}
export default useGetStoreDetails
