import { useTranslation } from 'react-i18next'
import { sinUpSteps } from '../constants'
import { useState } from 'react'
import useGenerateOTP from '../hooks/useGenerateOTP'
import { Button } from '../../../components/button/Button'
import { logo } from '../../../constants/media'
import PolicyConsent from './PolicyConsent'
import { useAuth } from 'react-oidc-context'

const EmailStep = ({ setSearchParams, getParams, searchParams }) => {
    const auth = useAuth()

    const { t } = useTranslation('signup')

    // email and policy
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('')
    const { mutate: generateOTP, status: generateOTPStatus } = useGenerateOTP()
    const [isPolicyAgreed, setIsPolicyAgreed] = useState(false)
    const [policyError, setPolicyError] = useState(null)

    const handleEmailChange = (e) => {
        const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/
        const value = e?.target?.value?.trim()
        const isEmailValid = emailRegex.test(value)
        if (isEmailValid) {
            setEmailError('')
        } else {
            setEmailError(t('signup:please_enter_valid_email_add'))
        }
        setEmail(value)
    }
    const handelPolicy = () => {
        setIsPolicyAgreed((prev) => !prev)
        setPolicyError(false)
    }
    const handleEmailSubmit = () => {
        if (!email || !isPolicyAgreed || emailError) {
            if (!email) setEmailError(t('signup:please_enter_valid_email_add'))
            if (!isPolicyAgreed) setPolicyError(true)
            return
        }

        const formBody = {
            email,
        }

        generateOTP(
            { formData: formBody },
            {
                onSuccess: () => {
                    setSearchParams({ ...getParams(searchParams), email, step: sinUpSteps.otp })
                },
                onError: (error) => {
                    console.log(error)
                    setEmailError(error?.response?.data?.response_body?.message || 'something went wrong! ')
                },
            }
        )
    }
    const onKeycloakLogin = () => {
        auth.signinRedirect()
    }
    return (
        <section>
            <img src={logo} className=' h-16 mx-auto' alt='' />
            <p className=' mb-0 pt-8 text-2xl text-center font-semibold text-brandDark'>{t('signup:sign_up')}</p>
            <p className=' pt-2 text-sm text-center  text-brandTextPrimary'>{t('signup:welcome')}</p>
            <div className='  w-full !pt-8'>
                <label htmlFor='email' className=' !text-sm text-brandGray4'>
                    {t('common:email')} <span className='text-red-500'>*</span>
                </label>
                <input
                    type='email'
                    id='email'
                    className=' !py-2 !mt-1 w-full !border !border-solid !border-brandStroke !px-3 rounded-md'
                    value={email}
                    onChange={handleEmailChange}
                />
                {emailError && <p className=' text-sm !mb-0 text-brandRed !py-1'>{emailError}</p>}
                <PolicyConsent handelPolicy={handelPolicy} isPolicyAgreed={isPolicyAgreed} policyError={policyError} />
                <Button
                    className='w-full !mt-5'
                    disabled={generateOTPStatus === 'pending'}
                    isLoading={generateOTPStatus === 'pending'}
                    onClick={handleEmailSubmit}>
                    {t('signup:continue')}
                </Button>

                <p className=' text-sm text-brandTextPrimary pt-3'>
                    {t('already_have_an_account')}{' '}
                    <span className=' text-brandPrimary cursor-pointer' onClick={onKeycloakLogin}>
                        Login
                    </span>
                </p>
            </div>
        </section>
    )
}
export default EmailStep
