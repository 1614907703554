import { useQuery } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const formAttributeURL = process.env.REACT_APP_FORM_ATTRIBUTES

const useGetFormAttributes = (filterFn) => {
    const getFormAttributes = async () => {
        const res = await api.get(formAttributeURL)
        return res?.data
    }

    return useQuery({
        queryKey: ['form', 'attributes'],
        queryFn: getFormAttributes,
        select: typeof filterFn === 'function' ? filterFn : (data) => data,
        refetchOnWindowFocus: false,
        retry: false,
    })
}
export default useGetFormAttributes
