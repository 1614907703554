import { useEffect } from 'react'
import { deleteKeyValueFromObject } from '../../../utils/utils'

const Email = ({ attributeFelid, formData, setFormErrors, formErrors, setFormData }) => {
    const onTextChange = (e, attributeFelid) => {
        const value = e.target.value
        const attributeId = attributeFelid?.attribute_id
        const regexFromAttribute = attributeFelid?.regex || attributeFelid?.attribute_datatype?.regex
        const regexExceptionMessage =
            attributeFelid?.regex_exception_message || attributeFelid?.attribute_datatype?.regex_exception_message
        let testRegex = null
        try {
            testRegex = new RegExp(regexFromAttribute)
        } catch (error) {
            testRegex = null
        }

        if (value) {
            if (!testRegex?.test(value?.trim())) {
                setFormErrors({
                    ...formErrors,
                    [attributeId]: regexExceptionMessage,
                })
            } else if (formErrors?.[attributeId]) {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        } else {
            if (attributeFelid?.mandatory) {
                setFormErrors({ ...formErrors, [attributeId]: `${attributeFelid?.name} is required` })
            } else {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        }
    }
    useEffect(() => {
        const savedValue = attributeFelid?.attribute_values?.[0]?.[0]?.value
        console.log(savedValue)
        if (savedValue) {
            onTextChange({ target: { value: savedValue } }, attributeFelid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFelid, attributeFelid?.attribute_values])

    const fieldError = formErrors?.[attributeFelid?.attribute_id]
    return (
        <input
            type='email'
            id={attributeFelid?.name}
            className={` rounded-lg w-full border  py-2 px-3  ${fieldError ? 'border-brandRed outline-brandRed' : 'border-brandStroke'}`}
            disabled={attributeFelid?.is_static}
            value={formData[attributeFelid?.attribute_id] || ''}
            onChange={(e) => onTextChange(e, attributeFelid)}
            required={attributeFelid?.mandatory}
        />
    )
}
export default Email
