import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'
const generateOTPURL = process.env.REACT_APP_GENERATE_OPT
const useGenerateOTP = () => {
    const generateOTP = async ({ formData }) => {
        const res = await api.post(generateOTPURL, formData)
        return res.data
    }
    return useMutation({ mutationFn: generateOTP })
}
export default useGenerateOTP
