import React from 'react'
import { cva } from 'class-variance-authority'
import Spin from '../Spin'
import { cn } from '../../utils/utils'
const buttonVariants = cva(
    'inline-flex items-center justify-center no-underline  whitespace-nowrap rounded-md text-base font-medium transition-colors focus-visible:outline-none border-none  disabled:opacity-50  disabled:cursor-not-allowed',
    {
        variants: {
            variant: {
                default: 'bg-brandPrimary text-brandPrimaryForeground   hover:opacity-90',
                secondary:
                    'bg-brandSecondary text-brandSecondaryForeground border border-brandSecondaryForeground border-solid   hover:opacity-70',
                disabled: ' bg-brandGray3 text-brandDark5',
            },
            size: {
                default: ' py-3 px-8 rounded-lg',
                md: 'py-2 px-4',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
        },
    }
)
const Button = React.forwardRef(({ className, variant, size, children, isLoading, ...props }, ref) => {
    return (
        <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
            {isLoading && <Spin />}
            {children}
        </button>
    )
})
export { Button, buttonVariants }
