import { useTranslation } from 'react-i18next'
import { shopPNG } from '../../../constants/media'

const StoreDetails = ({ storeDetails }) => {
    const { t } = useTranslation('dashboard')

    return (
        <section className=' flex flex-col-reverse md:flex-row gap-x-5 justify-between w-full '>
            <div>
                <p className=' text-2xl font-semibold text-brandSecondaryForeground'>{t('store_details')}</p>
                <div className=' mt-2 text-brandGray4 text-sm space-y-4'>
                    <p>
                        {t('store_name')}: {storeDetails?.store_name}
                    </p>
                    <p>
                        {t('user_name')}: {storeDetails?.store_username}
                    </p>
                    <p>
                        {t('common:email')}: {storeDetails?.store_email}
                    </p>
                    <p>
                        {t('store_link')}:{' '}
                        <a
                            href={storeDetails?.store_link}
                            target='_blank'
                            className=' text-blue-400 break-all'
                            rel='noreferrer'>
                            {storeDetails?.store_link}
                        </a>
                    </p>
                    <p>
                        {t('store_management_link')}:{' '}
                        <a
                            href={storeDetails?.smp_link}
                            target='_blank'
                            className=' text-blue-400 break-all'
                            rel='noreferrer'>
                            {storeDetails?.smp_link}
                        </a>
                    </p>
                </div>
            </div>
            <div className=' shrink-0 mx-auto '>
                <div className=' relative max-w-[366px] w-full h-[276px]'>
                    <img src={shopPNG} alt='' className=' mx-auto w-full h-full' />
                    {/* <div className=' w-[215px] h-[29px] bg-brandPrimary rounded border-2 absolute top-11 right-[74px] border-black'>
                        <p className=' text-center font-bold text-brandSecondaryForeground'>
                            {storeDetails?.store_name}
                        </p>
                    </div> */}
                </div>
            </div>
        </section>
    )
}
export default StoreDetails
