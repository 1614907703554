import { useTranslation } from 'react-i18next'
import Alert from '../../../components/Alert'
const policies = JSON.parse(process.env.REACT_APP_POLICIES || '[]')

const PolicyConsent = ({ handelPolicy, isPolicyAgreed, policyError }) => {
    const { t } = useTranslation('signup')

    return (
        <>
            <section className=' flex pt-4 gap-x-2 items-start'>
                <input
                    type='checkbox'
                    name='policy'
                    id='policy'
                    className=' mt-1'
                    onChange={handelPolicy}
                    checked={isPolicyAgreed}
                />
                <div className=' text-sm text-brandTextPrimary'>
                    <label htmlFor='policy'>{t('agreed_to_terms_and_conditions')}</label>
                    <div className=' flex items-center  flex-wrap gap-x-1'>
                        {policies?.length > 0 &&
                            policies?.map((policy, index) => {
                                return (
                                    <div key={policy?.name}>
                                        <a href={policy?.link} target='_blank' rel='noreferrer' className='  underline'>
                                            {policy?.name}
                                        </a>
                                        {index + 1 < policies?.length ? <span>,</span> : <span>.</span>}
                                    </div>
                                )
                            })}
                    </div>
                </div>
            </section>
            {policyError && (
                <div className=' pt-2'>
                    <Alert type='error'>{t('check_box_before_proceeding')}</Alert>
                </div>
            )}
        </>
    )
}
export default PolicyConsent
