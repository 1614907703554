import { useMutation } from '@tanstack/react-query'
import api from '../lib/apiClient'

const storeOrderSubscribe = process.env.REACT_APP_STORE_ORDER_SUBSCRIBE

const useDeleteOrder = () => {
    const deleteData = async ({ planId }) => {
        const data = await api.delete(storeOrderSubscribe, {
            params: {
                plan_id: planId,
            },
        })
        return data?.data
    }
    return useMutation({ mutationFn: deleteData, retry: false })
}
export default useDeleteOrder
