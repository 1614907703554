import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import Cookies from 'js-cookie'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.min.css'
import Header from '../components/header/Header'
import OrdersProvider from '../context/OrdersProvider'
import StoreOrderPlansProvider from '../context/StoreOrderPlansProvider'
import Footer from '../components/footer/Footer'

const Root = () => {
    const auth = useAuth()
    useEffect(() => {
        if (auth && auth?.user && auth.user?.access_token) {
            Cookies.set('access_token', auth.user?.access_token)
            localStorage.setItem(
                'user',
                auth.user?.profile?.name || auth.user?.profile?.given_name || auth.user?.profile?.preferred_username
            )
        }
    }, [auth])
    return (
        <StoreOrderPlansProvider>
            <OrdersProvider>
                <ToastContainer position={'top-right'} />
                <section className=' min-h-screen flex flex-col '>
                    {/* Header */}
                    <Header />
                    <section className=' bg-gray-50 grow pt-[93px] pb-10'>
                        <Outlet />
                    </section>
                    {/* Footer */}
                    <Footer />
                </section>
            </OrdersProvider>
        </StoreOrderPlansProvider>
    )
}

export default Root
