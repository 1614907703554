import { useTranslation } from 'react-i18next'
import { logo } from '../../constants/media'
import useGetFormAttributes from './hooks/useGetFormAttributes'
import { CompletedStep, CurrentStep, PendingStep } from './components/Stepper'
import OnboardingForm from './components/OnboardingForm'
import { useContext, useEffect, useState } from 'react'
import { OrderContext } from '../../context/OrdersProvider'
import { useNavigate } from 'react-router-dom'

const OnboardingDetails = () => {
    const { t } = useTranslation('onboardingDetails')
    const orderDetails = useContext(OrderContext)
    const navigate = useNavigate()
    const {
        data: formAttributesData,
        status: formAttributesStatus,
        error: formAttributesError,
        isRefetching: isFormAttributesRefetching,
        refetch: refetchFormAttributes,
    } = useGetFormAttributes(attributesFilter)

    const [currentStepId, setCurrentStepId] = useState(null)

    // if order exist redirect to root
    useEffect(() => {
        const orderDetailsStatus = orderDetails?.ordersData?.response_body?.order_status || null

        if (
            orderDetails?.ordersStatus === 'success' &&
            (orderDetailsStatus === 'Placed' || orderDetailsStatus === 'Completed')
        ) {
            navigate('/')
        }
    }, [navigate, orderDetails?.ordersData?.response_body?.order_status, orderDetails?.ordersStatus])

    return (
        <section className=' py-10 w-full'>
            {formAttributesStatus === 'pending' && (
                <div className=' max-w-[644px] w-full h-60  mx-auto shadow-brandShadow bg-gray-200 animate-pulse rounded-lg py-6 px-8'></div>
            )}
            {formAttributesStatus === 'success' && (
                <div className=' max-w-[644px] w-full mx-auto shadow-brandShadow bg-white rounded-lg py-6 px-8'>
                    <img src={logo} alt='' className=' w-44 mx-auto' />
                    <p className=' pt-3 text-brandDark font-semibold text-2xl text-center'>{t('form_title')}</p>
                    <div className=' flex items-center justify-center pt-6'>
                        {formAttributesData?.formAttributes?.length > 0 &&
                            formAttributesData?.formAttributes?.map((attributeGroup) => {
                                return (
                                    <div key={attributeGroup?.attribute_group_id}>
                                        {(currentStepId || formAttributesData?.currentStep?.attribute_group_id) ===
                                        attributeGroup?.attribute_group_id ? (
                                            <CurrentStep
                                                isLastStep={attributeGroup?.isLastAttributeGroup}
                                                stepName={attributeGroup?.name}
                                                key={attributeGroup?.attribute_group_id}
                                                isFilled={attributeGroup?.is_filled}
                                            />
                                        ) : attributeGroup?.is_filled ? (
                                            <div
                                                className=' cursor-pointer'
                                                onClick={() => setCurrentStepId(attributeGroup?.attribute_group_id)}>
                                                <CompletedStep
                                                    isLastStep={attributeGroup?.isLastAttributeGroup}
                                                    stepName={attributeGroup?.name}
                                                    key={attributeGroup?.attribute_group_id}
                                                />
                                            </div>
                                        ) : (
                                            <PendingStep
                                                isLastStep={attributeGroup?.isLastAttributeGroup}
                                                stepName={attributeGroup?.name}
                                                key={attributeGroup?.attribute_group_id}
                                            />
                                        )}
                                    </div>
                                )
                            })}
                    </div>
                    <OnboardingForm
                        formAttributes={formAttributesData?.formAttributes?.find(
                            (att) =>
                                att?.attribute_group_id ===
                                (currentStepId || formAttributesData?.currentStep?.attribute_group_id)
                        )}
                        refetchFormAttributes={refetchFormAttributes}
                        isFormAttributesRefetching={isFormAttributesRefetching}
                        setCurrentStepId={setCurrentStepId}
                        key={currentStepId || formAttributesData?.currentStep?.attribute_group_id}
                    />
                </div>
            )}
            {formAttributesStatus === 'error' && (
                <div className=' max-w-[644px] flex items-center justify-center w-full h-60  mx-auto shadow-brandShadow bg-white  rounded-lg py-6 px-8'>
                    <p className=' text-xl text-brandRed'>{t('error_getting_form')}</p>
                </div>
            )}
        </section>
    )
}
export default OnboardingDetails

const attributesFilter = (res) => {
    let formAttributes = res?.response_body?.onboarding_attribute_group_data
    formAttributes = formAttributes?.sort((a, b) => a?.ordering_id - b?.ordering_id)
    formAttributes = formAttributes?.map((attributeGroup, index) => {
        const isLastAttributeGroup = index === formAttributes?.length - 1
        attributeGroup.isLastAttributeGroup = isLastAttributeGroup
        return attributeGroup
    })
    let currentStep = formAttributes?.find((att) => !att?.is_filled || att?.isLastAttributeGroup)
    return { formAttributes, currentStep }
}
