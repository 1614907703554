import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'
const regenerateOTPURL = process.env.REACT_APP_REGENERATE_OPT
const useRegenerateOTP = () => {
    const regenerateOTP = async ({ formData }) => {
        const res = await api.post(regenerateOTPURL, formData)
        return res.data
    }
    return useMutation({ mutationFn: regenerateOTP })
}
export default useRegenerateOTP
