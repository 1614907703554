import { cva } from 'class-variance-authority'
import React from 'react'
import { cn } from '../utils/utils'

const alertVariants = cva('w-full border gap-x-3 rounded-sm py-2 px-4 flex items-start text-brandTextPrimary text-sm', {
    variants: {
        type: {
            error: 'border-[#FFCCC7] bg-[#FFF1F0] ',
            success: ' border-[#B7EB8F] bg-[#F6FFED]',
        },
    },
    defaultVariants: {
        type: 'error',
    },
})
const Alert = React.forwardRef(({ description, type, className, children, ...props }, ref) => {
    return (
        <div className={cn(alertVariants({ type, className }))} ref={ref} {...props}>
            {type === 'error' && (
                <div className=' mt-1'>
                    <ErrorSVG />
                </div>
            )}
            {type === 'success' && (
                <div className=' mt-1'>
                    <SuccessSVG />
                </div>
            )}
            <p>{children}</p>
        </div>
    )
})

const SuccessSVG = () => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7 0.000976562C3.13438 0.000976562 0 3.13535 0 7.00098C0 10.8666 3.13438 14.001 7 14.001C10.8656 14.001 14 10.8666 14 7.00098C14 3.13535 10.8656 0.000976562 7 0.000976562ZM10.0234 4.71504L6.73281 9.27754C6.68682 9.34173 6.62619 9.39404 6.55595 9.43012C6.48571 9.46621 6.40787 9.48503 6.32891 9.48503C6.24994 9.48503 6.17211 9.46621 6.10186 9.43012C6.03162 9.39404 5.97099 9.34173 5.925 9.27754L3.97656 6.57754C3.91719 6.49473 3.97656 6.3791 4.07812 6.3791H4.81094C4.97031 6.3791 5.12187 6.45566 5.21562 6.58691L6.32812 8.13066L8.78438 4.72441C8.87813 4.59473 9.02812 4.5166 9.18906 4.5166H9.92188C10.0234 4.5166 10.0828 4.63223 10.0234 4.71504Z'
                fill='#52C41A'
            />
        </svg>
    )
}

const ErrorSVG = () => {
    return (
        <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M7 0.000976562C3.13438 0.000976562 0 3.13535 0 7.00098C0 10.8666 3.13438 14.001 7 14.001C10.8656 14.001 14 10.8666 14 7.00098C14 3.13535 10.8656 0.000976562 7 0.000976562ZM9.58438 9.66035L8.55313 9.65566L7 7.8041L5.44844 9.6541L4.41563 9.65879C4.34688 9.65879 4.29063 9.6041 4.29063 9.53379C4.29063 9.5041 4.30156 9.47598 4.32031 9.45254L6.35313 7.03066L4.32031 4.61035C4.30143 4.58745 4.29096 4.55878 4.29063 4.5291C4.29063 4.46035 4.34688 4.4041 4.41563 4.4041L5.44844 4.40879L7 6.26035L8.55156 4.41035L9.58281 4.40566C9.65156 4.40566 9.70781 4.46035 9.70781 4.53066C9.70781 4.56035 9.69688 4.58848 9.67813 4.61191L7.64844 7.03223L9.67969 9.4541C9.69844 9.47754 9.70938 9.50566 9.70938 9.53535C9.70938 9.6041 9.65313 9.66035 9.58438 9.66035Z'
                fill='#FF4D4F'
            />
        </svg>
    )
}
export default Alert
