import { useTranslation } from 'react-i18next'
import { storeBuildingPNG } from '../../../constants/media'
const email = process.env.REACT_APP_CUSTOMER_SUPPORT_MAIL
const WaitForStore = () => {
    const { t } = useTranslation('dashboard')

    return (
        <div className='   w-full mx-auto'>
            <p className=' text-center text-lg font-bold text-brandDark pb-5'>{t('store_details')}</p>
            <img src={storeBuildingPNG} alt='' className=' max-w-48 mx-auto w-full ' />
            <p className=' text-center text-brandTextPrimary  mx-auto mt-5'>{t('wait_for_build')}</p>
            <p className=' text-center text-brandTextPrimary  mx-auto '>
                {t('please_write_to')}{' '}
                <a href={`mailto:${email}`} className=' text-brandPrimary'>
                    {email}
                </a>{' '}
                {t('any_queries')}
            </p>
        </div>
    )
}
export default WaitForStore
