import { useEffect } from 'react'
import { deleteKeyValueFromObject } from '../../../utils/utils'
const storeNameSuffix = process.env.REACT_APP_STORE_SUFFIX

const TextField = ({ attributeFelid, formData, setFormErrors, formErrors, setFormData }) => {
    const keyName = attributeFelid?.key_name

    const onTextChange = (e, attributeFelid) => {
        // Attribute specific logic
        const value = keyName === 'store_name' ? e.target.value?.replace(/\s/g, '') : e.target.value
        //

        const attributeId = attributeFelid?.attribute_id
        const regexFromAttribute = attributeFelid?.regex || attributeFelid?.attribute_datatype?.regex
        const regexExceptionMessage =
            attributeFelid?.regex_exception_message || attributeFelid?.attribute_datatype?.regex_exception_message
        let testRegex = null
        try {
            testRegex = new RegExp(regexFromAttribute)
        } catch (error) {
            testRegex = null
        }

        if (value) {
            if (!testRegex?.test(value?.trim())) {
                setFormErrors({
                    ...formErrors,
                    [attributeId]: regexExceptionMessage,
                })
            } else if (formErrors?.[attributeId]) {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        } else {
            if (attributeFelid?.mandatory) {
                setFormErrors({ ...formErrors, [attributeId]: `${attributeFelid?.name} is required` })
            } else {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        }
    }
    useEffect(() => {
        const savedValue = attributeFelid?.attribute_values?.[0]?.[0]?.value
        console.log(savedValue)
        if (savedValue) {
            onTextChange({ target: { value: savedValue } }, attributeFelid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFelid, attributeFelid?.attribute_values])
    const fieldError = formErrors?.[attributeFelid?.attribute_id]
    return (
        <div className=' flex items-end gap-x-1'>
            <input
                type='text'
                id={attributeFelid?.name}
                className={` rounded-lg w-full border  py-2 px-3  ${fieldError ? 'border-brandRed outline-brandRed' : 'border-brandStroke'}`}
                disabled={attributeFelid?.is_static}
                value={formData[attributeFelid?.attribute_id] || ''}
                onChange={(e) => onTextChange(e, attributeFelid)}
                required={attributeFelid?.mandatory}
            />
            {keyName === 'store_name' && <p className='text-brandGray4 '>.{storeNameSuffix}</p>}
        </div>
    )
}
export default TextField
