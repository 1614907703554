import { createBrowserRouter } from 'react-router-dom'

import Root from './pages/Root'
import Dashboard from './pages/dashboard/Dashboard'
import OnboardingDetails from './pages/onboardingDetails/OnboardingDetails'
import Signup from './pages/signup/SignupV2'
import Checkout from './pages/checkout/Checkout'
import Cashfree from './pages/cashfree/Cashfree'
import PublicRoute from './components/PublicRoute'
import PrivateRoute from './components/PrivateRoute'
import Logout from './components/Logout'
import SignInRedirect from './components/SignInRedirect'
import OrderStatus from './pages/orderStatus/OrderStatus'

export default createBrowserRouter([
    {
        path: '/',
        element: <Root />,

        children: [
            {
                index: true,
                element: (
                    <PrivateRoute>
                        <Dashboard />
                    </PrivateRoute>
                ),
            },
            {
                path: 'onboarding-details',
                element: (
                    <PrivateRoute>
                        <OnboardingDetails />
                    </PrivateRoute>
                ),
            },
            {
                path: 'signup',
                element: (
                    <PublicRoute>
                        <Signup />
                    </PublicRoute>
                ),
            },
            {
                path: 'checkout',
                element: (
                    <PrivateRoute>
                        <Checkout />
                    </PrivateRoute>
                ),
            },
            {
                path: 'cashfree',
                element: (
                    <PrivateRoute>
                        <Cashfree />
                    </PrivateRoute>
                ),
            },
            {
                path: 'logout',
                element: <Logout />,
            },
            {
                path: 'signin_redirect',
                element: <SignInRedirect />,
            },
            {
                path: '/order/status',
                element: (
                    <PrivateRoute>
                        <OrderStatus />
                    </PrivateRoute>
                ),
            },
        ],
    },
])
