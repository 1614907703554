import { useQuery } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const storeOrderSummary = process.env.REACT_APP_STORE_ORDER_SUMMARY

export const useFetchOrderSummary = ({ plan_id, from_pin, to_pin }) => {
    const getData = async () => {
        const params = {
            'from-pincode': from_pin,
            'plan-id': plan_id,
            'to-pincode': to_pin,
        }
        const res = await api.get(storeOrderSummary, {
            params: params,
        })
        console.log(res)
        if (res.status !== 200) {
            throw new Error('Error in fetching data')
        }
        if (res?.data?.error_type === 'error') {
            throw new Error(res?.data?.response_message)
        }
        return res?.data?.response_body?.message
    }

    return useQuery({
        queryKey: ['order_summary'],
        queryFn: getData,
        refetchOnWindowFocus: false,
        enabled: !!plan_id && !!to_pin,
        retry: false,
    })
}
