import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const storeOrderSubscribe = process.env.REACT_APP_STORE_ORDER_SUBSCRIBE

const useUpdateOrder = () => {
    const postData = async ({ formBody, orderId }) => {
        const data = await api.put(storeOrderSubscribe, formBody, {
            params: {
                'order-id': orderId,
            },
        })
        return data?.data
    }
    return useMutation({ mutationFn: postData, retry: false })
}
export default useUpdateOrder
