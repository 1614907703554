import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { sinUpSteps } from './constants'
import EmailStep from './components/EmailStep'
import OTPStep from './components/OTPStep'
import FinishingSignup from './components/FinishingSignup'

const SignupV2 = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const signUpStep = searchParams.get('step')?.toLocaleLowerCase()
    const email = searchParams.get('email')?.toLocaleLowerCase()

    useEffect(() => {
        if (!signUpStep) setSearchParams({ ...getParams(searchParams), step: sinUpSteps.email })
    }, [searchParams, setSearchParams, signUpStep])
    return (
        <section className='min-h-screen flex items-start justify-center '>
            <div className='bg-white p-8 m-9 rounded-lg shadow-lg max-w-[496px] w-full'>
                {/* Email */}
                {searchParams.get('step') === sinUpSteps.email && (
                    <EmailStep getParams={getParams} setSearchParams={setSearchParams} searchParams={searchParams} />
                )}

                {/* OTP */}
                {searchParams.get('step') === sinUpSteps.otp && (
                    <OTPStep
                        email={email}
                        getParams={getParams}
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                    />
                )}
                {/* FORM */}
                {searchParams.get('step') === sinUpSteps.userDetails && <FinishingSignup email={email} />}
            </div>
        </section>
    )
}
export default SignupV2

const getParams = (searchParams) => {
    const params = {}
    searchParams.forEach((value, key) => {
        params[key] = value
    })
    return params
}
