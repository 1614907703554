import { useEffect } from 'react'
import { deleteKeyValueFromObject } from '../../../utils/utils'
const phonePrefix = process.env.REACT_APP_PHONE_PREFIX

const TelePhone = ({ attributeFelid, formData, setFormErrors, formErrors, setFormData }) => {
    const keyName = attributeFelid?.key_name

    const onTelChange = (e, attributeFelid) => {
        const value = e.target.value
        const attributeId = attributeFelid?.attribute_id
        const regexFromAttribute = attributeFelid?.regex || attributeFelid?.attribute_datatype?.regex
        const regexExceptionMessage =
            attributeFelid?.regex_exception_message || attributeFelid?.attribute_datatype?.regex_exception_message
        let testRegex = null
        try {
            testRegex = new RegExp(regexFromAttribute)
        } catch (error) {
            testRegex = null
        }

        if (value) {
            if (!testRegex?.test(value?.trim())) {
                setFormErrors({
                    ...formErrors,
                    [attributeId]: regexExceptionMessage,
                })
            } else if (formErrors?.[attributeId]) {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        } else {
            if (attributeFelid?.mandatory) {
                setFormErrors({ ...formErrors, [attributeId]: `${attributeFelid?.name} is required` })
            } else {
                setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: value }
            })
        }
    }
    useEffect(() => {
        const savedValue = attributeFelid?.attribute_values?.[0]?.[0]?.value
        console.log(savedValue)
        if (savedValue) {
            onTelChange({ target: { value: savedValue } }, attributeFelid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFelid, attributeFelid?.attribute_values])

    const fieldError = formErrors?.[attributeFelid?.attribute_id]

    const isKeyPhoneNumber = keyName === 'phone_number'
    return (
        <div
            className={` flex border rounded-lg overflow-hidden has-[:focus]:outline   ${fieldError ? 'border-brandRed outline-brandRed outline-1' : 'border-brandStroke outline-2'} `}>
            {isKeyPhoneNumber && (
                <div className='py-2 px-3 bg-[#FAFAFA] border-r border-brandStroke '>
                    <p className=' text-[#637381]'>{phonePrefix}</p>
                </div>
            )}

            <input
                type='tel'
                id={attributeFelid?.name}
                className={`w-full  py-2 px-3 outline-none`}
                disabled={attributeFelid?.is_static}
                value={formData[attributeFelid?.attribute_id] || ''}
                onChange={(e) => onTelChange(e, attributeFelid)}
                required={attributeFelid?.mandatory}
            />
        </div>
    )
}
export default TelePhone
