import { useTranslation } from 'react-i18next'
import { Button } from '../../button/Button'
import useDeleteOrder from '../../../hooks/useDeleteOrder'
import { toast } from 'react-toastify'
import { useContext } from 'react'
import { StoreOrderPlanContext } from '../../../context/StoreOrderPlansProvider'

const CancelSubscriptionModal = ({ setIsDeleteModalOpen }) => {
    const { t } = useTranslation()
    const { planDetails } = useContext(StoreOrderPlanContext)
    const { mutate: deleteOrder, status: deleteOrderStatus } = useDeleteOrder()

    const cancelSubscription = () => {
        const planId = planDetails?.plan_id

        deleteOrder(
            { planId },
            {
                onSuccess: () => {
                    toast.success(t('common:successfully_Cancelled'))
                    window.location.reload()
                    setIsDeleteModalOpen(false)
                },
                onError: () => {
                    toast.error(t('common:error_cancelling_subscription'))
                },
            }
        )
    }

    return (
        <section className=' absolute inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70'>
            <div className=' max-w-lg rounded-lg w-full  bg-white p-6 '>
                <p className=' text-brandDark font-semibold  text-lg'>{t('common:canceling_subscription_question')}</p>
                <div className=' mt-8 gap-x-4 flex justify-end items-center'>
                    <Button
                        variant='secondary'
                        size='md'
                        disabled={deleteOrderStatus === 'pending'}
                        onClick={() => setIsDeleteModalOpen(false)}>
                        {t('common:no')}
                    </Button>
                    <Button
                        onClick={cancelSubscription}
                        size='md'
                        disabled={deleteOrderStatus === 'pending'}
                        isLoading={deleteOrderStatus === 'pending'}>
                        {t('common:yes')}
                    </Button>
                </div>
            </div>
        </section>
    )
}
export default CancelSubscriptionModal
