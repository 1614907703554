import { useNavigate, useSearchParams } from 'react-router-dom'
import useUpdateOrder from './hooks/useUpdateOrder'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/button/Button'
import { useQueryClient } from '@tanstack/react-query'
import Spinner from '../../components/spinner/Spinner'
import { logo } from '../../constants/media'
import {successImg } from "../../constants/media"

const OrderStatus = () => {
    const queryClient = useQueryClient()
    const { t } = useTranslation('common')
    let [searchParams] = useSearchParams()
    const { mutate: updateOrder, status: updateOrderStatus } = useUpdateOrder()
    const navigate = useNavigate()
    useEffect(() => {
        const orderId = searchParams.get('oid')
        const status = searchParams.get('status')
        if (orderId && status === 'FAILED') {
            const formBody = {
                status: 'Failed',
            }

            updateOrder(
                { formBody, orderId },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['orders'] })
                    },
                }
            )
        } else if (orderId) {
            const formBody = {
                status: 'Placed',
            }

            updateOrder(
                { formBody, orderId },
                {
                    onSuccess: () => {
                        queryClient.invalidateQueries({ queryKey: ['orders'] })
                    },
                }
            )
        }
    }, [queryClient, searchParams, updateOrder])

    return (
        <section className=' pt-10 xl:pt-28 px-5 flex justify-center h-full items-center'>
            {updateOrderStatus === 'pending' ? (
                <section className=' mt-3 flex justify-center items-center'>
                    <Spinner />
                </section>
            ) : searchParams.get('status') === 'FAILED' || updateOrderStatus === 'error' ? (
                <div className=' flex flex-col h-full items-center gap-y-5 bg-white max-w-[560px] w-full p-12 rounded-lg shadow-brandShadowTwo'>
                    <p className=' text-brandDark text-2xl'>{t('failed_to_subscribe')}</p>
                    <Button onClick={() => navigate('/checkout')} className='mx-auto'>
                        {t('retry')}
                    </Button>
                </div>
            ) : (
                <div className=' flex flex-col h-full items-center gap-y-5 bg-white max-w-[560px] w-full p-12 rounded-lg shadow-brandShadowTwo'>
                    <img src={logo} alt='' className=' h-[59px]' />
                    <p className=' text-brandDark text-center text-2xl'>{t('successfully_subscribed')}</p>
                    <div>
                        {/* <svg
                            width='136'
                            height='130'
                            viewBox='0 0 136 130'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M113 28.75H23V17.5H113V28.75ZM88.7562 73.75H79.25V83.2562C75.7062 88.6 73.625 94.9563 73.625 101.875C73.625 103.787 73.85 105.644 74.1313 107.5H23V73.75H17.375V62.5L23 34.375H113L118.625 62.5V70.0938C115.081 68.8563 111.312 68.125 107.375 68.125C100.456 68.125 94.1 70.2062 88.7562 73.75ZM68 73.75H34.25V96.25H68V73.75ZM120.537 84.1L100.344 104.294L91.4 95.35L84.875 101.875L100.344 118.75L127.062 92.0312L120.537 84.1Z'
                                fill='#FFB703'
                            />
                        </svg> */}
                         <img src={successImg}  width='136'
                            height='130' />
                    </div>
                    {/* <p className=' text-brandDark text-center text-2xl'>{t('successfully_subscribed')}</p> */}
                    <p className=' text-brandTextPrimary text-center'>{t('subscribed_sub')}</p>
                    {/* <p onClick={() => navigate('/')} className=' text-center cursor-pointer text-brandPrimary'>
                        {t('go_to_dashboard')}
                    </p> */}
                     <Button
                                            onClick={() => navigate('/')}
                                            className='w-full'
                                            // isLoading={isSubscribeLoading}
                                            // disabled={isSubscribeLoading}
                                            >
                                            {t('go_to_dashboard')}
                                            
                                        </Button>
                </div>
            )}
        </section>
    )
}
export default OrderStatus
