import { useEffect } from 'react'
import { deleteKeyValueFromObject } from '../../../utils/utils'

const SingleSelect = ({ attributeFelid, formData, setFormErrors, formErrors, setFormData }) => {
    const handelSingleSelect = (e, attributeFelid) => {
        const value = Number(e.target.value)
        const attributeId = attributeFelid?.attribute_id
        if (value) {
            setFormErrors({ ...deleteKeyValueFromObject(attributeId, formErrors) })
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: [value] }
            })
        } else {
            if (attributeFelid?.mandatory) {
                setFormErrors({ ...formErrors, [attributeId]: `${attributeFelid?.name} is required` })
            }
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeId]: [value] }
            })
        }
    }
    useEffect(() => {
        const savedValue = attributeFelid?.attribute_values?.[0]?.[0]?.option

        if (savedValue) {
            handelSingleSelect({ target: { value: savedValue?.[0] } }, attributeFelid)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFelid, attributeFelid?.attribute_values])

    const fieldError = formErrors?.[attributeFelid?.attribute_id]

    return (
        <select
            id={attributeFelid?.attribute_id}
            className={` rounded-lg w-full border  py-2 px-3  ${fieldError ? 'border-brandRed outline-brandRed' : 'border-brandStroke'}`}
            value={formData[attributeFelid?.attribute_id]?.[0]}
            onChange={(e) => handelSingleSelect(e, attributeFelid)}>
            <option value=''></option>
            {attributeFelid?.attribute_option?.map((options) => {
                return (
                    <option value={options?.id} key={options?.id}>
                        {options?.option_name}
                    </option>
                )
            })}
        </select>
    )
}
export default SingleSelect
