import { useTranslation } from 'react-i18next'
import { logo } from '../../../constants/media'
import { Button } from '../../../components/button/Button'
import { useNavigate } from 'react-router-dom'

const SubscribeNow = ({ planDetails }) => {
    const { t } = useTranslation('dashboard')
    const navigate = useNavigate()

    return (
        <div className=' flex gap-y-3 justify-center w-full items-center flex-col'>
            <img src={logo} alt='' className='h-12' />
            <p className=' py-2 text-brandSecondaryForeground font-semibold'>{t('checkout:paid_plan')}</p>
            {planDetails?.reccuring_amount && (
                <div className=' flex flex-col items-center justify-center py-2'>
                    <p className=' text-2xl font-semibold'>
                        <span className=' text-brandSecondaryForeground'>₹{planDetails?.reccuring_amount}</span>
                        <span className='   text-brandGray4'>/ {t('common:month')}</span>
                        <span className='   text-brandGray4'> + {t('common:tax')}</span>
                    </p>
                </div>
            )}
            <div>
                <p className=' text-center text-brandTextPrimary'>{t('subscribe_text_one')}</p>
                <p className=' text-center text-brandTextPrimary'>{t('subscribe_text_two')}</p>
                <p className=' text-center text-brandTextPrimary'>{t('subscribe_text_three')}</p>
            </div>
            <Button onClick={() => navigate('/checkout')} className='w-full max-w-[649px]'>
                {t('subscribe_now')}
            </Button>
        </div>
    )
}
export default SubscribeNow
