import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const signupPostURL = process.env.REACT_APP_SIGNUP_API

const usePostSignup = () => {
    const postSignup = async ({ formData }) => {
        const res = await api.post(signupPostURL, formData)
        return res?.data
    }

    return useMutation({
        mutationFn: postSignup,
    })
}

export default usePostSignup
