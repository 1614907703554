import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const onBoardingFormAttributesURL = process.env.REACT_APP_ONBOARDING_FORM_ATTRIBUTES

const useUpdateFormAttributes = () => {
    const updateFormAttributes = async ({ formBody }) => {
        const res = await api.put(onBoardingFormAttributesURL, formBody)
        return res?.data
    }
    return useMutation({ mutationFn: updateFormAttributes })
}
export default useUpdateFormAttributes
