import { useTranslation } from 'react-i18next'
import { useFetchOrderSummary } from './hooks/useFetchOrderSummary'
import { usePostStoreOrder } from './hooks/usePostStoreOrder'
import useGetFormAttributes from '../onboardingDetails/hooks/useGetFormAttributes'
import { Button } from '../../components/button/Button'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrderContext } from '../../context/OrdersProvider'
import { toast } from 'react-toastify'
import { StoreOrderPlanContext } from '../../context/StoreOrderPlansProvider'
import { logo } from '../../constants/media'
import { getFloatingNumber } from '../../utils/utils'

const Checkout = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const from_pin = '560008'
    const { data: formAttributesData } = useGetFormAttributes(attributesFilter)
    const { planDetails: plan_id, isLoading: loadingId } = useContext(StoreOrderPlanContext)
    const orderDetailsData = useContext(OrderContext)

    const {
        data: orderSummary,
        isLoading: loadingSummary,
        status,
    } = useFetchOrderSummary({ plan_id: plan_id?.plan_id, from_pin, to_pin: formAttributesData?.pinCode })
    const { mutate: subscribe, isPending: pendingSubscribe } = usePostStoreOrder()
    const [isSubscribeLoading, setIsSubscribeLoading] = useState(false)
    useEffect(() => {
        const orderDetailsStatus = orderDetailsData?.ordersData?.response_body?.order_status || null
        if (
            orderDetailsData?.ordersStatus === 'success' &&
            (orderDetailsStatus === 'Placed' || orderDetailsStatus === 'Completed')
        ) {
            navigate('/')
        }
    }, [navigate, orderDetailsData?.ordersData?.response_body?.order_status, orderDetailsData?.ordersStatus])

    const handleSubscribe = () => {
        const formBody = {
            plan_id: plan_id?.plan_id,
            to_pincode: formAttributesData?.pinCode,
            from_pincode: from_pin,
        }
        setIsSubscribeLoading(true)
        subscribe(formBody, {
            onSuccess: (details) => {
                if (!details.payment_captured) {
                    navigate(`/order/status?oid=${details.id}&status=success`)
                } else {
                    if (details.payment_reference_details?.authLink) {
                        window.location.href = details.payment_reference_details?.authLink
                    } else {
                        toast.error('Payment link not found')
                    }
                }
            },
            onError: (error) => {
                setIsSubscribeLoading(false)
                toast.error('Error in posting data')
            },
        })
    }

    const isLoading = loadingId && loadingSummary
    return (
        <section className=' px-5'>
            {!isLoading && status === 'pending' ? (
                <div className=' flex flex-col md:flex-row justify-center mx-auto gap-7 mt-9'>
                    <div className=' w-full h-[200px] md:w-[302px] bg-gray-100 rounded animate-pulse'></div>
                    <div className='w-full md:w-[602px] h-[400px] bg-gray-100 rounded animate-pulse'></div>
                </div>
            ) : (
                <div className=' max-w-max mx-auto '>
                    <div
                        // className={`flex bg-white p-10 w-auto  rounded-lg shadow-brandShadow flex-col md:flex-row justify-center mx-auto gap-7 mt-9 ${orderSummary === undefined ? 'items-center' : 'items-start'}`}>
                        className={` bg-white p-10 w-auto  rounded-lg shadow-brandShadow mt-9 mx-auto`}>
                        <div className='flex items-center justify-center p-2 mb-2'>
                            <img src={logo} alt='' className=' h-[59px] w-[143px] text-center' />
                        </div>
                        <div
                            className={`flex flex-col md:flex-row justify-center  gap-7  ${orderSummary === undefined ? 'items-center' : 'items-start'}`}>
                            {/* first block */}
                            {/* <div className=' w-full shadow-brandShadow rounded-[6px] md:w-[302px]'>
                            <div className=' p-4 border-b border-[#F0F0F0] '>
                                <p className=' text-brandDark font-bold text-base '>{t('checkout:free_trial')}</p> */}
                            <div className='border border-2  border-[#000000] bg-[#F3F4F6] w-full shadow-brandShadow rounded-[32px] md:w-[302px]'>
                                <div className=' p-4 '>
                                    <p className=' text-brandDark font-bold text-[24px] '>{t('checkout:free_trial')}</p>
                                </div>
                                <div className=' py-[14px] px-6'>
                                    <p className=' text-brandTextPrimary font-normal text-sm'>
                                        {t('checkout:description1')}
                                    </p>
                                </div>
                                {/* <div className='p-4 border-b  border-[#F0F0F0] flex flex-row justify-between'>
                                <p className=' text-brandDark font-bold text-base '>{t('checkout:paid_plan')}</p>
                                <p className='text-brandDark font-bold text-base'>
                                    ₹{orderSummary?.base_amount}
                                    <span className=' text-brandTextPrimary font-normal text-sm'>
                                        / {t('common:month')}
                                    </span>
                                    <span className=' text-brandTextPrimary font-normal text-sm'>
                                        +{t('common:tax')}
                                    </span>
                                </p>
                            </div> */}
                                <div className='flex flex-col p-4 text-brandPrimary'>
                                    <span className='text-[48px] font-semibold'>₹{orderSummary?.base_amount}*</span>
                                    <span className='text-[sm]  -mt-3'>billed monthly</span>
                                </div>
                                {/* <div className='py-[14px] px-6'>
                                <p className=' text-brandTextPrimary font-normal text-sm'>
                                    {t('checkout:description2')}
                                </p>
                            </div> */}
                                <div className='p-4 mt-16 text-[#637381] test-sm'>*Plus applicable sales tax</div>
                            </div>
                            {/* second block */}
                            <div className='w-full  md:w-[602px] flex flex-col gap-5'>
                                {orderSummary === undefined ? (
                                    <p className='!mx-auto text-brandTextPrimary font-normal text-base'>
                                        {t('checkout:ordersummary_not_found')}
                                    </p>
                                ) : (
                                    <>
                                        <div>
                                            <p className=' text-brandDark font-semibold text-2xl'>
                                                {t('checkout:start_your_free_trial')}
                                            </p>
                                        </div>
                                        <div className=' bg-[#F3F4F6] py-4 px-10 flex flex-col gap-6'>
                                            <div className='flex'>
                                                <h4 className=' text-brandDark font-medium text-lg'>
                                                    {t('checkout:order_summary')}
                                                </h4>
                                                <span className='text-sm  m-1'>
                                                    (Order will be processed after the trial period)
                                                </span>
                                            </div>
                                            <div className=' flex flex-col gap-2'>
                                                <div className='flex flex-row justify-between'>
                                                    <p className=' text-brandDark font-normal text-sm'>
                                                        {/* {t('checkout:item_total')} (1 Items) */}
                                                        Community Edition Subscription
                                                    </p>
                                                    <p className='text-brandDark font-normal text-sm'>
                                                        Rs.{getFloatingNumber(orderSummary?.base_amount, 2)}
                                                    </p>
                                                </div>
                                                {orderSummary?.igst && (
                                                    <div className='flex flex-row justify-between '>
                                                        <p className='text-brandDark font-normal text-sm'>
                                                            {t('checkout:igist')}
                                                        </p>
                                                        <p className='text-brandDark font-normal text-sm'>
                                                            Rs.{getFloatingNumber(orderSummary?.igst, 2)}
                                                        </p>
                                                    </div>
                                                )}
                                                {orderSummary?.cgst && (
                                                    <>
                                                        <div className='flex flex-row justify-between '>
                                                            <p className='text-brandDark font-normal text-sm'>
                                                                {t('checkout:sgst')}
                                                            </p>
                                                            <p className='text-brandDark font-normal text-sm'>
                                                                Rs.{getFloatingNumber(orderSummary?.sgst, 2)}
                                                            </p>
                                                        </div>
                                                        <div className='flex flex-row justify-between '>
                                                            <p className='text-brandDark font-normal text-sm'>
                                                                {t('checkout:cgst')}
                                                            </p>
                                                            <p className='text-brandDark font-normal text-sm'>
                                                                Rs.{getFloatingNumber(orderSummary?.cgst, 2)}
                                                            </p>
                                                        </div>
                                                    </>
                                                )}
                                                <div className=' border border-[rgba(0, 0, 0, 0.06)]'></div>
                                                <div className='flex flex-row justify-between'>
                                                    <p className=' text-brandDark font-medium text-base'>
                                                        {t('checkout:grand_total')}
                                                    </p>
                                                    <p className=' text-brandDark font-medium text-base'>
                                                        Rs.{getFloatingNumber(orderSummary?.total, 2)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <Button
                                            onClick={handleSubscribe}
                                            isLoading={isSubscribeLoading}
                                            disabled={isSubscribeLoading}>
                                            {t('checkout:begin_free_trail')}
                                        </Button>
                                    </>
                                )}
                                <div className='text-sm mt-3'>
                                    <li>No credit card needed to get started!</li>
                                    <li>Cancel anytime to avoid being billed.</li>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export default Checkout

const attributesFilter = (res) => {
    let formAttributes = res?.response_body?.onboarding_attribute_group_data

    let isFormFilled = formAttributes?.filter((att) => !att?.is_filled)
    // if (isFormFilled?.length > 0) {
    //     const base_url = window.location.origin
    //     window.location.replace(base_url + '/onboarding-details')
    // }

    const addressData = formAttributes?.find((att) => att?.name === 'Address')
    let pinCode = null
    addressData?.attribute?.forEach((att) => {
        if (att?.name === 'ZIP Code') {
            pinCode = att?.attribute_values?.[0]?.[0]?.value || null
        }
    })

    return { pinCode }
}
