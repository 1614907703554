import React from 'react'
import './spinner.css'

const Spinner = () => {
    return (
        <div style={{ textAlign: '-webkit-center' }}>
            <div className='loader h-24 w-24 rounded-full border-8 border-t-8 border-gray-200 ease-linear'></div>
        </div>
    )
}

export default Spinner
