import Spinner from './spinner/Spinner'

const FullPageLoader = () => {
    return (
        <section className=' h-screen flex justify-center items-center'>
            <Spinner />
        </section>
    )
}
export default FullPageLoader
