import { useQuery } from '@tanstack/react-query'
import api from '../lib/apiClient'
import { useAuth } from 'react-oidc-context'

const storeOrderSubscribe = process.env.REACT_APP_STORE_ORDER_SUBSCRIBE

const useGetOrders = (planId) => {
    const auth = useAuth()
    const getOrders = async () => {
        const res = await api.get(storeOrderSubscribe, {
            params: {
                plan_id: planId,
            },
        })
        return res.data
    }
    const queryKey = ['orders']
    if (planId) queryKey.push(planId)
    return useQuery({ queryKey, queryFn: getOrders, enabled: auth?.isAuthenticated })
}
export default useGetOrders
