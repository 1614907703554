import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'

const Logout = () => {
    const auth = useAuth()
    const { t } = useTranslation()
    useEffect(() => {
        Cookies.remove('access_token')
        localStorage.removeItem('user')
        void auth.signoutRedirect()
    }, [])
    return (
        <section className=' min-h-screen flex items-center justify-center'>
            <p className=' text-xl '>{t('common:logging_out')}</p>
        </section>
    )
}
export default Logout
