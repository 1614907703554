import { Navigate } from 'react-router-dom'
import { useAuth } from 'react-oidc-context'
import Spinner from './spinner/Spinner'

const PrivateRoute = ({ children }) => {
    const auth = useAuth()
    if (auth.isLoading) {
        return (
            <section className=' h-screen flex justify-center items-center'>
                <Spinner />
            </section>
        )
    }
    if (auth.isAuthenticated) return children
    else return <Navigate to='/signin_redirect' />
}
export default PrivateRoute
