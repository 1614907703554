import { useTranslation } from 'react-i18next'
import { THISSVG } from '../../constants/media'

const policies = JSON.parse(process.env.REACT_APP_POLICIES || '[]')
const Footer = () => {
    const { t } = useTranslation('footer')
    return (
        <section className=' bg-white py-4 px-5 w-full '>
            <div className=' flex items-center justify-center flex-wrap gap-x-3'>
                {policies?.length > 0 &&
                    policies?.map((policy, index) => {
                        return (
                            <div key={policy?.name}>
                                {index !== 0 && <span className=' mr-3 text-brandDark text-sm '>|</span>}
                                <a
                                    href={policy?.link}
                                    target='_blank'
                                    rel='noreferrer'
                                    className=' text-xs text-brandDark hover:underline'>
                                    {policy?.name}
                                </a>
                            </div>
                        )
                    })}
            </div>
            <div className=' mt-2 flex justify-center items-center flex-wrap gap-x-6'>
                <p className=' text-brandDark text-xs'>
                    <span>{t('copyright')}</span> <span>{t('business_name')}</span>
                </p>
                {/* <span className=' text-brandDark text-sm '>|</span>
                <div className=' flex items-center  '>
                    <p className=' text-xs text-brandDark'>{t('built_by')}</p>
                    <img src={THISSVG} alt='' className=' h-7' />
                </div> */}
            </div>
        </section>
    )
}
export default Footer
