import { createContext } from 'react'
import { useFetchOrderPlan } from '../pages/checkout/hooks/useFetchOrderPlan'
export const StoreOrderPlanContext = createContext([])
const defaultPlanId = 'one-month-plan'
const StoreOrderPlansProvider = ({ children }) => {
    const {
        data: planDetails,
        status: planDetailsStatus,
        refetch: refetchPlanDetails,
        isLoading,
    } = useFetchOrderPlan(planeIdFilter)

    return (
        <StoreOrderPlanContext.Provider value={{ planDetails, planDetailsStatus, refetchPlanDetails, isLoading }}>
            {children}
        </StoreOrderPlanContext.Provider>
    )
}
export default StoreOrderPlansProvider

const planeIdFilter = (data) => {
    return data?.find((d) => d?.plan_id === defaultPlanId)
}
