import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const uploadDocumentsURL = process.env.REACT_APP_DOCUMENT
const useUploadDocuments = () => {
    const uploadDocuments = async ({ formBody }) => {
        const res = await api.post(uploadDocumentsURL, formBody)
        return res?.data
    }
    return useMutation({ mutationFn: uploadDocuments })
}
export default useUploadDocuments
