import React, { useState, useEffect } from 'react'
import { load } from '@cashfreepayments/cashfree-js'
import { useLocation, useNavigate } from 'react-router-dom'

const Cashfree = ({ mode }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const locationState = location?.state
    const paymentSessionId = locationState && locationState.paymentSessionId
    const orderID = locationState && locationState.orderID
    localStorage.setItem('payDone', false)
    localStorage.setItem('noBack', true)
    const [cashfreeInitialized, setCashfreeInitialized] = useState(false)
    const [cashfree, setCashfree] = useState(null)

    useEffect(() => {
        const initializeCashfree = async () => {
            const cashfreeInstance = await load({
                mode: 'sandbox', // or production
            })
            setCashfree(cashfreeInstance)
            setCashfreeInitialized(true)
        }

        initializeCashfree()
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!cashfreeInitialized || !cashfree) return // Skip if Cashfree not initialized yet

        let checkoutOptions = {
            paymentSessionId: paymentSessionId,
            redirectTarget: document.getElementById('cf_checkout'),
            appearance: {
                width: '425px',
                height: '650px',
            },
        }

        cashfree.checkout(checkoutOptions).then((result) => {
            console.log(result)
            // if (result.error) {
            //     localStorage.setItem('payDone', false)
            //     navigate('/')
            // }
            // if (result.redirect) {
            //     localStorage.setItem('payDone', false)
            //     navigate('/')
            // }
            // if (result.paymentDetails) {
            //     localStorage.setItem('payDone', true)
            //     localStorage.setItem('checkoutSuccessData', true)
            //     navigate(`/checkout`)
            // }
        })
    }, [cashfreeInitialized, cashfree])

    return (
        <section className=' mt-36 bg-white  w-full py-10 min-h-screen'>
            <div className='flex justify-center items-center'>
                <div id='cf_checkout'></div>
            </div>
        </section>
    )
}

export default Cashfree
