import Axios from 'axios'
import { getAuthToken } from '../utils/utils'

const baseURL = process.env.REACT_APP_BASE_URL

const api = Axios.create({
    baseURL: baseURL,
})

api.interceptors.request.use(
    function (config) {
        const token = getAuthToken()
        config.headers.Accept = 'application/json'
        if (token) {
            config.headers.Authorization = token
        }
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        const token = getAuthToken()

        if (error?.response?.status === 401 && token) {
            const base_url = window.location.origin
            window.location.replace(base_url + '/logout')
        }
        return Promise.reject(error)
    }
)

export default api
