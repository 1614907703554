import React, { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import { AuthProvider } from 'react-oidc-context'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from 'react-router-dom'
import reportWebVitals from './reportWebVitals'
import routes from './routes'
import './lib/i18n'
import './index.css'
import FullPageLoader from './components/FullPageLoader'
import { WebStorageStateStore } from 'oidc-client-ts'

const queryClient = new QueryClient()
let guestTokenPayload = JSON.parse(process.env.REACT_APP_GUEST_LOGIN)
let keycloakUrl = process.env.REACT_APP_KEYCLOAK_BASE_URL
const redirectUri = process.env.REACT_APP_REDIRECT_URI
const postLogoutRedirectUri = process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI

const oidcConfig = {
    config_id: `${guestTokenPayload.realmname}`,
    authority: `${keycloakUrl}/realms/${guestTokenPayload.realmname}`,
    client_id: `${guestTokenPayload.client_id}`,
    client_secret: `${guestTokenPayload.client_secret}`,
    redirect_uri: redirectUri,
    post_logout_redirect_uri: postLogoutRedirectUri,
    onSigninCallback: (_user) => {
        window.history.replaceState({}, document.title, window.location.pathname)
    },
    userStore: new WebStorageStateStore({ store: window.localStorage }),
}
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <React.StrictMode>
        <Suspense fallback={<FullPageLoader />}>
            <AuthProvider {...oidcConfig}>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={routes} />
                </QueryClientProvider>
            </AuthProvider>
        </Suspense>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
