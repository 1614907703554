import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'react-oidc-context'

const SignInRedirect = () => {
    const auth = useAuth()
    const { t } = useTranslation()
    useEffect(() => {
        localStorage.setItem('page', `${window.location.origin}`)
        void auth.signinRedirect()
    }, [])
    return (
        <section className=' min-h-screen flex items-center justify-center'>
            <p className=' text-xl '> {t('common:redirect_to_signin')}</p>
        </section>
    )
}
export default SignInRedirect
