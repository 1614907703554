import { useQuery } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const storeOrderPlanAPI = process.env.REACT_APP_STORE_ORDER_PLAN

export const useFetchOrderPlan = (filterFn) => {
    const getOrderId = async () => {
        const orderData = await api.get(storeOrderPlanAPI)
        return orderData?.data?.response_body
    }

    return useQuery({
        queryKey: ['getOrderId'],
        queryFn: getOrderId,
        select: typeof filterFn === 'function' ? filterFn : (data) => data,
    })
}
