import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { documentFileTypes, documentTypeFileExtension, imageFileExtension, imageFileType } from '../constants'
import useUploadImages from '../hooks/useUploadImages'
import useUploadDocuments from '../hooks/useUploadDocuments'
import { deleteKeyValueFromObject } from '../../../utils/utils'
import { toast } from 'react-toastify'
import Spin from '../../../components/Spin'
import { certificateSVG } from '../../../constants/media'

const baseURL = process.env.REACT_APP_FILE_BASE_URL

const FileUpload = ({ attributeFelid, setFormData, setFormErrors, formData, formErrors }) => {
    const { mutate: uploadImage, status: uploadImageStatus } = useUploadImages()
    const { mutate: uploadDocuments, status: uploadDocumentStatus } = useUploadDocuments()
    const { t } = useTranslation('onboardingDetails')

    const attributeId = attributeFelid?.attribute_id

    const hiddenFileInput = useRef(null)
    const uploadProfileImageHandler = () => {
        if (uploadDocumentStatus !== 'pending' && uploadDocumentStatus !== 'pending') {
            hiddenFileInput.current.click()
        }
    }

    useEffect(() => {
        const savedValue = attributeFelid?.attribute_values?.[0]?.[0]?.value
        console.log(savedValue)
        if (savedValue) {
            setFormData((prevFormData) => {
                return { ...prevFormData, [attributeFelid?.attribute_id]: savedValue }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributeFelid, attributeFelid?.attribute_values])

    const handleImageChange = (event) => {
        const fileUploaded = event.target.files[0]
        console.log(fileUploaded, 'fileUploaded')
        const fileType = fileUploaded?.type

        if (documentFileTypes.includes(fileType)) {
            let documentFormData = new FormData()
            documentFormData.append('original_document', fileUploaded)
            documentFormData.append('extension ', documentTypeFileExtension[fileType?.toLowerCase()])
            uploadDocuments(
                { formBody: documentFormData },
                {
                    onSuccess: (data) => {
                        const filePath = data?.response_body?.document_full_path
                        if (filePath) {
                            setFormData((prevFormData) => {
                                return { ...prevFormData, [attributeFelid?.attribute_id]: filePath }
                            })
                            setFormErrors((prevFormError) => {
                                console.log(prevFormError)
                                return { ...deleteKeyValueFromObject(attributeId, prevFormError) }
                            })
                        }
                        toast.success(t('document_uploaded'))
                    },
                    onError: () => {
                        toast.error(t('error_saving_file'))
                    },
                }
            )
        } else if (imageFileType.includes(fileType)) {
            let fileExtension = fileUploaded?.name?.split('.')
            fileExtension = fileExtension?.[fileExtension?.length - 1]
            let imageFormData = new FormData()
            imageFormData.append('original_image', fileUploaded)
            imageFormData.append('image_type', 'store_onboarding')
            imageFormData.append('extension', fileExtension?.toLowerCase())
            uploadImage(
                { formBody: imageFormData },
                {
                    onSuccess: (data) => {
                        const filePath = data?.response_body?.image_full_path
                        if (filePath) {
                            setFormData((prevFormData) => {
                                return { ...prevFormData, [attributeFelid?.attribute_id]: filePath }
                            })
                            setFormErrors((prevFormError) => {
                                return { ...deleteKeyValueFromObject(attributeId, prevFormError) }
                            })
                        }
                        toast.success(t('document_uploaded'))
                    },
                    onError: () => {
                        toast.error(t('error_saving_file'))
                    },
                }
            )
        } else {
            toast.error(t('file_type_error'))
        }
    }
    const savedPath = formData?.[attributeId]

    const handelPreview = () => {
        window.open(baseURL + savedPath, '_blank')
    }
    const checkIfImageOrDocument = (url) => {
        const documentFileExtensions = Object.values(documentTypeFileExtension)
        let fileType = url?.split('.')
        fileType = fileType?.[fileType?.length - 1]
        if (documentFileExtensions.includes(fileType)) {
            return 'doc'
        } else if (imageFileExtension.includes(fileType)) {
            return 'img'
        }
        return null
    }

    const handelFileDelete = () => {
        if (attributeFelid?.mandatory) {
            setFormErrors((prevFormErrors) => {
                return { ...prevFormErrors, [attributeId]: `${attributeFelid?.name} is required` }
            })
        }
        setFormData((prevFormData) => {
            return { ...prevFormData, [attributeFelid?.attribute_id]: '' }
        })
    }

    const fieldError = formErrors?.[attributeFelid?.attribute_id]

    return (
        <>
            <div className=' flex items-center space-x-5'>
                <div
                    className={` h-[104px] w-[104px]  border border-dashed  rounded-sm flex justify-center items-center bg-[#FAFAFA]
                     ${
                         uploadDocumentStatus === 'pending' || uploadImageStatus === 'pending'
                             ? 'opacity-50 animate-pulse cursor-progress'
                             : ' cursor-pointer'
                     } ${fieldError ? 'border-brandRed ' : 'border-brandStroke'}`}
                    onClick={uploadProfileImageHandler}>
                    <div className=' flex flex-col items-center gap-y-2'>
                        {uploadImageStatus === 'pending' || uploadDocumentStatus === 'pending' ? (
                            <>
                                <Spin />
                                <p className=' select-none'>{t('uploading')}</p>
                            </>
                        ) : (
                            <>
                                <svg
                                    width='14'
                                    height='14'
                                    viewBox='0 0 14 14'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                        d='M7.46863 1.37598H6.53113C6.44779 1.37598 6.40613 1.41764 6.40613 1.50098V6.40723H1.75024C1.66691 6.40723 1.62524 6.44889 1.62524 6.53223V7.46973C1.62524 7.55306 1.66691 7.59473 1.75024 7.59473H6.40613V12.501C6.40613 12.5843 6.44779 12.626 6.53113 12.626H7.46863C7.55196 12.626 7.59363 12.5843 7.59363 12.501V7.59473H12.2502C12.3336 7.59473 12.3752 7.55306 12.3752 7.46973V6.53223C12.3752 6.44889 12.3336 6.40723 12.2502 6.40723H7.59363V1.50098C7.59363 1.41764 7.55196 1.37598 7.46863 1.37598Z'
                                        fill='#023047'
                                    />
                                </svg>
                                <p className=' select-none'>{t('upload')}</p>
                            </>
                        )}
                    </div>
                </div>
                {savedPath && (
                    <div className='h-[104px] w-[104px] group  border  border-brandStroke rounded-sm flex justify-center items-center'>
                        <div className='w-full h-full relative p-2 flex justify-center items-center'>
                            {checkIfImageOrDocument(savedPath) === 'img' && (
                                <img src={baseURL + savedPath} className=' w-full  h-full' alt='' />
                            )}
                            {checkIfImageOrDocument(savedPath) === 'doc' && <img src={certificateSVG} alt='' />}
                            <div className='   items-center justify-center gap-x-2 group-hover:flex absolute hidden inset-0 m-2 bg-black bg-opacity-50'>
                                <svg
                                    width='16'
                                    height='16'
                                    viewBox='0 0 16 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className=' cursor-pointer text-white hover:text-brandPrimary'
                                    onClick={handelPreview}>
                                    <path
                                        d='M15.6822 7.5404C13.9894 3.97433 11.4305 2.17969 8.0001 2.17969C4.56796 2.17969 2.01082 3.97433 0.31796 7.54219C0.250059 7.68597 0.214844 7.843 0.214844 8.00201C0.214844 8.16102 0.250059 8.31805 0.31796 8.46183C2.01082 12.0279 4.56975 13.8225 8.0001 13.8225C11.4322 13.8225 13.9894 12.0279 15.6822 8.46005C15.8197 8.17076 15.8197 7.83505 15.6822 7.5404ZM8.0001 12.5368C5.11975 12.5368 3.01082 11.0761 1.52332 8.00112C3.01082 4.92612 5.11975 3.4654 8.0001 3.4654C10.8805 3.4654 12.9894 4.92612 14.4769 8.00112C12.9912 11.0761 10.8822 12.5368 8.0001 12.5368ZM7.92867 4.85826C6.19296 4.85826 4.78582 6.2654 4.78582 8.00112C4.78582 9.73683 6.19296 11.144 7.92867 11.144C9.66439 11.144 11.0715 9.73683 11.0715 8.00112C11.0715 6.2654 9.66439 4.85826 7.92867 4.85826ZM7.92867 10.0011C6.82332 10.0011 5.92867 9.10647 5.92867 8.00112C5.92867 6.89576 6.82332 6.00112 7.92867 6.00112C9.03403 6.00112 9.92868 6.89576 9.92868 8.00112C9.92868 9.10647 9.03403 10.0011 7.92867 10.0011Z'
                                        fill='currentColor'
                                    />
                                </svg>
                                <svg
                                    width='16'
                                    height='16'
                                    viewBox='0 0 16 16'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                    className=' cursor-pointer text-white hover:text-brandRed'
                                    onClick={handelFileDelete}>
                                    <path
                                        d='M5.28544 2.14118H5.14258C5.22115 2.14118 5.28544 2.0769 5.28544 1.99833V2.14118H10.714V1.99833C10.714 2.0769 10.7783 2.14118 10.8569 2.14118H10.714V3.4269H11.9997V1.99833C11.9997 1.36797 11.4872 0.855469 10.8569 0.855469H5.14258C4.51222 0.855469 3.99972 1.36797 3.99972 1.99833V3.4269H5.28544V2.14118ZM14.2854 3.4269H1.71401C1.39794 3.4269 1.14258 3.68225 1.14258 3.99833V4.56975C1.14258 4.64833 1.20686 4.71261 1.28544 4.71261H2.36401L2.80508 14.0519C2.83365 14.6608 3.33722 15.1412 3.94615 15.1412H12.0533C12.664 15.1412 13.1658 14.6626 13.1944 14.0519L13.6354 4.71261H14.714C14.7926 4.71261 14.8569 4.64833 14.8569 4.56975V3.99833C14.8569 3.68225 14.6015 3.4269 14.2854 3.4269ZM11.9158 13.8555H4.08365L3.65151 4.71261H12.3479L11.9158 13.8555Z'
                                        fill='currentColor'
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <input
                type='file'
                className='hidden'
                ref={hiddenFileInput}
                onChange={handleImageChange}
                id={attributeFelid?.name}
                accept='application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg, image/png'
            />
        </>
    )
}

export default FileUpload
