import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { OrderContext } from '../../context/OrdersProvider'
import useGetStoreDetails from './hooks/useGetStoreDetails'
import Spinner from '../../components/spinner/Spinner'
import PaymentDetails from './components/PaymentDetails'
import { StoreOrderPlanContext } from '../../context/StoreOrderPlansProvider'
import SubscribeNow from './components/SubscribeNow'
import StoreDetails from './components/StoreDetails'
import WaitForStore from './components/WaitForStore'

const Dashboard = () => {
    const { t } = useTranslation('dashboard')
    const { planDetails, planDetailsStatus } = useContext(StoreOrderPlanContext)
    const orderDetails = useContext(OrderContext)
    const { data: storeDetails, status: storeDetailsStatus } = useGetStoreDetails({
        orderId: orderDetails?.ordersData?.response_body?.id,
    })
    const [isDashBoardLoading, setIsDashBoardLoading] = useState(true)

    const navigate = useNavigate()
    useEffect(() => {
        const orderDetailsStatus = orderDetails?.ordersData?.response_body?.order_status || null
        if (
            orderDetails?.ordersStatus === 'success' &&
            (orderDetailsStatus === 'Failed' || orderDetailsStatus === 'Created' || !orderDetailsStatus)
        ) {
            navigate('/onboarding-details')
        } else if (orderDetails?.ordersStatus === 'success' && orderDetailsStatus) {
            setIsDashBoardLoading(false)
        }
    }, [navigate, orderDetails?.ordersData?.response_body?.order_status, orderDetails?.ordersStatus])

    const orderStatus = orderDetails?.ordersData?.response_body?.order_status || null
    const isStoreCreated = storeDetails?.length > 0 && storeDetails?.[0]?.store_link && storeDetails?.[0]?.smp_link

    return (
        <section className=' w-full '>
            {isDashBoardLoading ? (
                <section className=' h-screen flex justify-center items-center'>
                    <Spinner />
                </section>
            ) : (
                <section className=' max-w-screen-brandMax px-5 min-[1240px]:px-0 mx-auto mt-6'>
                    <div className=' flex gap-8 items-start flex-col min-[1080px]:flex-row  justify-center'>
                        <div className=' max-w-[900px]  w-full flex  justify-between rounded-lg bg-white border-l-4 border-l-brandPrimary shadow-brandShadowTwo p-6 '>
                            {storeDetailsStatus === 'success' &&
                                (orderStatus === 'Cancelled' ? (
                                    <SubscribeNow planDetails={planDetails} />
                                ) : isStoreCreated ? (
                                    <StoreDetails storeDetails={storeDetails?.[0]} />
                                ) : (
                                    <WaitForStore />
                                ))}
                            {storeDetailsStatus === 'error' && (
                                <p className=' text-center py-5'>{t('error_getting_store_details')}</p>
                            )}
                        </div>

                        {/* <PaymentDetails
                            planDetails={planDetails}
                            planDetailsStatus={planDetailsStatus}
                            orderStatus={orderStatus}
                        /> */}
                    </div>
                </section>
            )}
        </section>
    )
}
export default Dashboard
