import { useTranslation } from 'react-i18next'
import { attributesType, optionBasedField, valueBasedField } from '../constants'
import { Button } from '../../../components/button/Button'
import { useState } from 'react'
import FileUpload from './FileUpload'
import useUpdateFormAttributes from '../hooks/useUpdateFormAttributes'
import TextField from './TextField'
import SingleSelect from './SingleSelect'
import TelePhone from './TelePhone'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Email from './Email'

const OnboardingForm = ({ formAttributes, refetchFormAttributes, isFormAttributesRefetching, setCurrentStepId }) => {
    const { t } = useTranslation('onboardingDetails')
    const { mutate: updateFormAttributes, status: updateFormAttributesStatus } = useUpdateFormAttributes()
    const [formData, setFormData] = useState({})
    const [formErrors, setFormErrors] = useState({})
    const navigate = useNavigate()
    const [isContinueBtn, setIsContinueBtn] = useState(false)

    console.log(formData, formErrors)
    const validateFormField = () => {
        let errorObject = { ...formErrors }
        formAttributes?.attribute?.forEach((att) => {
            if (att?.mandatory && !formData?.[att?.attribute_id] && att?.attribute_values?.length <= 0) {
                errorObject[att?.attribute_id] = `${att?.name} is required`
            }
        })
        setFormErrors({ ...errorObject })
        return errorObject
    }
    const handelFormSubmit = (isContinue) => {
        setIsContinueBtn(isContinue)
        const errors = validateFormField()
        if (Object.keys(errors)?.length === 0) {
            const formAttributesData = []
            formAttributes?.attribute?.forEach((att) => {
                const value = formData[att?.attribute_id]
                const isAttributeIncludes = Object.keys(formData)?.includes(String(att?.attribute_id))

                const fieldValueObj = {
                    attribute_id: att?.attribute_id,
                    data_type: att?.datatype_id,
                }
                if (isAttributeIncludes) {
                    if (valueBasedField.includes(att?.datatype_id)) fieldValueObj.value = value
                    else if (optionBasedField.includes(att?.datatype_id)) fieldValueObj.option = value
                    formAttributesData.push(fieldValueObj)
                }
            })
            const formBody = {
                attribute_data: [
                    {
                        attribute_group_id: formAttributes?.attribute_group_id,
                        attribute_group_data: formAttributesData,
                    },
                ],
            }
            if (formAttributesData?.length > 0) {
                updateFormAttributes(
                    { formBody },
                    {
                        onSuccess: () => {
                            toast.dismiss()
                            refetchFormAttributes()
                            if (isContinue) {
                                setCurrentStepId(null)
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: 'smooth',
                                })
                                if (formAttributes?.isLastAttributeGroup) {
                                    navigate('/checkout')
                                }
                            } else {
                                setCurrentStepId(formAttributes?.attribute_group_id)
                            }
                        },
                        onError: (error) => {
                            toast.error(error.response?.data?.response_message || t('error_saving_form'), {
                                autoClose: false,
                            })
                        },
                    }
                )
            }
            console.log(formBody)
        }
    }

    return (
        <>
            <section className=' space-y-6 mt-8'>
                {formAttributes?.attribute?.map((attributeFelid) => {
                    return (
                        <div className=' space-y-2' key={attributeFelid?.attribute_id}>
                            <label
                                htmlFor={attributeFelid?.name}
                                className=' flex text-brandGray4 text-sm items-center gap-x-1'>
                                {attributeFelid?.name} {attributeFelid?.mandatory && <Asterisk />}
                            </label>

                            {/* Text */}
                            {attributeFelid?.datatype_id === attributesType.TEXT && (
                                <TextField
                                    attributeFelid={attributeFelid}
                                    formData={formData}
                                    formErrors={formErrors}
                                    setFormData={setFormData}
                                    setFormErrors={setFormErrors}
                                />
                            )}

                            {/* Email */}
                            {attributeFelid?.datatype_id === attributesType.EMAIL && (
                                <Email
                                    attributeFelid={attributeFelid}
                                    formData={formData}
                                    formErrors={formErrors}
                                    setFormData={setFormData}
                                    setFormErrors={setFormErrors}
                                />
                            )}

                            {/* Phone */}
                            {attributeFelid?.datatype_id === attributesType.PHONE && (
                                <TelePhone
                                    attributeFelid={attributeFelid}
                                    formData={formData}
                                    formErrors={formErrors}
                                    setFormData={setFormData}
                                    setFormErrors={setFormErrors}
                                />
                            )}

                            {/* Documents */}
                            {attributeFelid?.datatype_id === attributesType.DOCUMENT && (
                                <FileUpload
                                    attributeFelid={attributeFelid}
                                    setFormData={setFormData}
                                    setFormErrors={setFormErrors}
                                    formData={formData}
                                    formErrors={formErrors}
                                />
                            )}

                            {/* Single Select */}
                            {attributeFelid?.datatype_id === attributesType.SINGLE_SELECTION && (
                                <SingleSelect
                                    attributeFelid={attributeFelid}
                                    formData={formData}
                                    formErrors={formErrors}
                                    setFormData={setFormData}
                                    setFormErrors={setFormErrors}
                                />
                            )}

                            {formErrors?.[attributeFelid?.attribute_id] && (
                                <p className=' text-sm text-brandRed'>{formErrors?.[attributeFelid?.attribute_id]}</p>
                            )}
                        </div>
                    )
                })}
            </section>
            <div className=' gap-x-6 mt-14 flex justify-end items-center'>
                <Button
                    variant='secondary'
                    onClick={() => handelFormSubmit(false)}
                    isLoading={
                        !isContinueBtn && (isFormAttributesRefetching || updateFormAttributesStatus === 'pending')
                    }
                    disabled={isFormAttributesRefetching || updateFormAttributesStatus === 'pending'}>
                    {t('save')}
                </Button>
                <Button
                    onClick={() => handelFormSubmit(true)}
                    isLoading={
                        isContinueBtn && (isFormAttributesRefetching || updateFormAttributesStatus === 'pending')
                    }
                    disabled={isFormAttributesRefetching || updateFormAttributesStatus === 'pending'}>
                    {t('Save_and_Continue')}
                </Button>
            </div>
        </>
    )
}
export default OnboardingForm

const Asterisk = () => {
    return (
        <svg width='7' height='6' viewBox='0 0 7 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.594 2.4C6.594 2.68 6.496 2.89 6.3 3.044C6.09 3.212 5.852 3.282 5.586 3.282C5.502 3.282 5.348 3.268 5.096 3.24C4.83 3.212 4.634 3.198 4.522 3.198C4.298 3.198 4.074 3.226 3.822 3.268C4.046 3.52 4.354 3.772 4.76 3.996C5.082 4.192 5.306 4.332 5.418 4.43C5.614 4.626 5.712 4.864 5.712 5.116C5.712 5.34 5.628 5.536 5.488 5.676C5.32 5.844 5.138 5.914 4.914 5.914C4.41 5.914 4.088 5.48 3.92 4.612C3.822 4.164 3.682 3.786 3.5 3.492C3.304 3.786 3.164 4.164 3.08 4.612C2.912 5.48 2.576 5.914 2.086 5.914C1.862 5.914 1.666 5.844 1.512 5.676C1.358 5.536 1.288 5.34 1.288 5.116C1.288 4.864 1.386 4.626 1.582 4.43C1.694 4.332 1.904 4.192 2.24 3.996C2.632 3.772 2.954 3.52 3.178 3.268C2.926 3.226 2.688 3.198 2.478 3.198C2.352 3.198 2.156 3.212 1.904 3.24C1.652 3.268 1.484 3.282 1.414 3.282C1.134 3.282 0.896 3.212 0.7 3.044C0.504 2.89 0.406 2.68 0.406 2.4C0.406 2.19 0.49 2.008 0.658 1.868C0.826 1.728 1.008 1.644 1.232 1.644C1.512 1.644 1.862 1.854 2.31 2.26C2.618 2.554 2.94 2.778 3.304 2.918C3.262 2.526 3.164 2.148 2.996 1.784C2.786 1.35 2.688 1.042 2.688 0.859999C2.688 0.622 2.758 0.411999 2.912 0.244C3.066 0.0759994 3.262 -0.00800037 3.5 -0.00800037C3.724 -0.00800037 3.92 0.0759994 4.088 0.244C4.228 0.411999 4.312 0.622 4.312 0.859999C4.312 1.042 4.2 1.35 4.004 1.784C3.836 2.148 3.724 2.526 3.696 2.918C4.046 2.778 4.382 2.554 4.69 2.26C5.124 1.854 5.488 1.644 5.768 1.644C5.978 1.644 6.174 1.728 6.342 1.868C6.51 2.008 6.594 2.19 6.594 2.4Z'
                fill='#FF4D4F'
            />
        </svg>
    )
}
