import { useMutation } from '@tanstack/react-query'
import api from '../../../lib/apiClient'

const storeOrderSubscribe = process.env.REACT_APP_STORE_ORDER_SUBSCRIBE

export const usePostStoreOrder = () => {
    const postData = async (formBody) => {
        const data = await api.post(storeOrderSubscribe, formBody)
        return data?.data?.response_body
    }

    return useMutation({
        mutationFn: postData,
    })
}
