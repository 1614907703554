import clsx from 'clsx'
import { twMerge } from 'tailwind-merge'
import Cookies from 'js-cookie'

export const cn = (...inputs) => {
    return twMerge(clsx(inputs))
}

export const deleteKeyValueFromObject = (key, object) => {
    let clonedObject = structuredClone(object)
    delete clonedObject[key]
    return clonedObject
}

export const getAuthToken = () => {
    return Cookies.get('access_token') || null
}

export const getFloatingNumber = (number, decimalPoints) => {
    try {
        const decimalNumber = Number(number)?.toFixed(decimalPoints)
        return decimalNumber
    } catch (error) {
        console.log(error)
        return number
    }
}
